import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useGlobalContext } from './GlobalProvider';
import { db } from '../inc/database';
import { getFileAccessByUser } from '../assets/near/utils';


const UserFilesContext = createContext();

export const useUserFilesContext = () => useContext(UserFilesContext);

export const UserFilesProvider = ({ children }) => {

  const { isLoggedIn, myUsername } = useGlobalContext();
  const jwtToken = localStorage.getItem('jwtToken');

  //Global variable for the files user uploaded
  const [uploads, setUploadedFiles] = useState([]);
  //Global variable for the files user can download
  const [availableFiles, setAvailableFiles] = useState([]);
  //Global variable for the files user downloaded
  const [downloads, setDownloads] = useState([]);
  //Global variable for the files user is currently seeding
  const [currentTransfers, setCurrentTransfers] = useState([]);

  //PEERJS VARIABLES
  const peerInstanceHost = useRef([]);
  const peerInstanceReceiver = useRef([]);

  useEffect(() => {
    if(isLoggedIn && jwtToken && myUsername) {
      // GET CURRENT DOWNLOADED FILES
      getCurrentDownloadedFiles();
    }
  }, [jwtToken, isLoggedIn, myUsername]);

   const getCurrentDownloadedFiles = async () => {
    const filesIds = await getFileAccessByUser(myUsername);
    console.log('BC files', filesIds);

    const currentDownloadedFiles = await db.downloadedFiles.toArray();
    console.log('currentDownloadedFiles', currentDownloadedFiles);

    if(currentDownloadedFiles.length > 0) {
      const allFileIds = [];
      for(const fileCid of filesIds) {
        const fileParts = fileCid.split('|');
        if(fileParts[0] !== undefined && fileParts[0] !== 'null') {
          allFileIds.push(fileParts[0]);
        }
        if(fileParts[1] !== undefined && fileParts[1] !== 'null') {
          allFileIds.push(fileParts[1]);
        }
      }
      const removeFromDB = currentDownloadedFiles.filter(obj => !allFileIds.some(fileId => fileId === obj.fileId)).map(obj => obj.fileId);
      let currentDownloads = currentDownloadedFiles;
      if(removeFromDB.length > 0) {
        removeFromDB.forEach(async fileId => {
          await db.downloadedFiles.where({fileId: fileId}).delete();
        });
        currentDownloads = currentDownloadedFiles.filter(file => removeFromDB.includes(file.fileId));
      }
      currentDownloads = currentDownloads.map(file => {
        file.downloading = true;
        file.percentage = 100;
        file.user = file.owner;
        file.anonymous = false;
        file.verified = true;
        return file;
      });
      console.log('currentDownloads: ', currentDownloads);
      setDownloads([...currentDownloads]);
    }
    // const userFiles = await window.contract.get_files_by_user({user_id: myUsername});
    // console.log('BC userFiles', userFiles);
    // const userFilesDB = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
    // console.log('userFilesDB', userFilesDB);
    // if(userFilesDB.length !== userFiles.length && !isRedirect) {
    //   setSyncModalContent('It seems that your local state is different from the blockchain state. Do you want to sync your local state with the blockchain state?');
    //   setOpenSyncModal(true);
    // }
  };

  return (
    <UserFilesContext.Provider value={{ availableFiles, setAvailableFiles, uploads, setUploadedFiles, downloads, setDownloads, peerInstanceHost, peerInstanceReceiver, currentTransfers, setCurrentTransfers }}>
      {children}
    </UserFilesContext.Provider>
  );
};