import { Fragment, useEffect, useState } from "react";
import AvailableFiles from "../components/tabs/AvailableFiles";
import Sidebar from "../layout/Sidebar";
import UploadFile from "../components/UploadFile";
import Uploads from "../components/tabs/Uploads";
import SocketEventsProvider from "../context/SocketEventsProvider";
import CurrentTransfers from "../components/tabs/CurrentTransfers";
import TransferFileProvider from "../context/TransferFileProvider";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import EmailModal from "../modals/EmailModal";
import HistoryWrapper from "../components/tabs/HistoryWrapper";
import CheckFile from "../components/tabs/CheckFile";
import EmailArchive from "../mails/pages/EmailArchive";
import Compose from "../mails/components/Compose";
import Drafts from "../mails/components/Drafts";
import { useGlobalContext } from "../context/GlobalProvider";
import OnboardingModal from "../modals/OnboardingModal";

// import { logout } from "../assets/near/utils";
// import logoutImage from '../assets/images/logout.png';

const { default: styled } = require("styled-components")

const DashboardWrap = styled.div`

  padding-left: 40px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  .main-menu {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    &.mobile-hide {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .gray-background {
    background: var(--Bg_9, #242C2F);
    padding: 25px;
    height: calc(100vh - 128px);
    overflow: scroll;

    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .logout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    margin-left: 24px;
    margin-top: 16px;

    padding: 12px 20px;
    border-radius: 24px;
    background: var(--Red_7, #62322F);
    gap: 8px;
    color: var(--Red_3, #FA9C9C);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    img {
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }
  }

`;


const Homepage = () => {

  const {openOnboardingModal} = useGlobalContext();
  const [currentActivePage, setCurrentActivePage] = useState('current-transfers');
  const [openEmailModal, setOpenEmailModal] = useState(false);

  const { fileHashURL } = useParams();
  const [ fileHash, setFileHash ] = useState(null);

  useEffect(() => {
    if(fileHashURL && fileHashURL.length === 64) {
      setFileHash(fileHashURL)
      setOpenEmailModal(true);
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  let addedUser = urlParams.get("signMeta");
  let sentEmail = urlParams.get('send_email')

  //Check if its redirect from smart contract transaction approval
  useEffect(() => {
    if(addedUser) {
      setCurrentActivePage('uploads');
    }
    if(sentEmail) {
      setCurrentActivePage('inbox');
    }
  },[]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeTab = (tab) => {
    setCurrentActivePage(tab);
    if(windowWidth < 768) {
      const body = document.querySelector("body");
      const modal = document.querySelector("#menu");
      modal.classList.toggle("mobile-hide");
      if (!modal.classList.contains("mobile-hide")) {
        // Disable scroll
        body.style.overflow = "show-mobile";
      } else {
        // Enable scroll
        body.style.overflow = "auto";
      }

      const tabs = document.querySelector("#tabs-wrap");
      tabs.classList.toggle("mobile-hide");
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  return (
    <SocketEventsProvider>
      <TransferFileProvider setCurrentActivePage={setCurrentActivePage}>
        {
          openEmailModal && (
            <EmailModal fileHash={fileHash} handleClose={() => setOpenEmailModal(false)} />
          )
        }
        {
          openOnboardingModal && (
            <OnboardingModal />
          )
        }
        <DashboardWrap className="grid grid-cols-12">
          <div id="menu" className="col-span-12 lg:col-span-3 mobile-hide main-menu">
            <Sidebar currentActivePage={currentActivePage} setCurrentActivePage={changeTab} />
            {/* <button className="logout-btn" onClick={() => logout()}>
              <img src={logoutImage} alt="logout" />
              <span>Logout</span>
            </button> */}
          </div>
          <div id="tabs-wrap" className="col-span-12 lg:col-span-9 gray-background">
            {
              currentActivePage === 'current-transfers' && (
                <Fragment>
                  <CurrentTransfers />
                </Fragment>
              )
            }
            {
              currentActivePage === 'uploads' && (
                <Fragment>
                  <UploadFile />
                  <Uploads />
                </Fragment>
              )
            }
            {
              currentActivePage === 'downloads' && (
                <AvailableFiles setCurrentActivePage={setCurrentActivePage} />
              )
            }
            {
              currentActivePage === 'check-file' && (
                <CheckFile />
              )
            }
            {
              currentActivePage === 'history' && (
                <HistoryWrapper />
              )
            }
            {
              currentActivePage === 'inbox' && (
                <EmailArchive setCurrentActivePage={setCurrentActivePage} />
              )
            }
            {
              currentActivePage === 'drafts' && (
                <Drafts />
              )
            }
            {
              currentActivePage === 'compose' && (
                <Compose />
              )
            }
          </div>
        </DashboardWrap>
      </TransferFileProvider>
    </SocketEventsProvider>
  );
}

export default Homepage;