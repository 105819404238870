import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useGlobalContext } from "./GlobalProvider";
import { db } from "../inc/database";
import { useUserFilesContext } from "./UserFilesProvider";
import { peerConfig } from "../inc/constants";
import { decryptMessage } from "../inc/encryption";
import { PublicKey } from "near-api-js/lib/utils";
import { sendMetrics } from "../inc/functions";

const TransferFileContext = createContext();

export const useTransferFileContext = () => { useContext(TransferFileContext) };

export default function TransferFileProvider({ children, setCurrentActivePage }) {
  const { socket, myUsername } = useGlobalContext();
  const { setCurrentTransfers, currentTransfers, downloads, setDownloads, peerInstanceReceiver, peerInstanceHost } = useUserFilesContext();

  const [currentFiles, setCurrentFiles] = useState([]);

  const [signedMessage, setSignedMessage] = useState({});
  const signedMessageRef = useRef(signedMessage);
 
  useEffect(() => {
    if(currentFiles.length > 0) {
      //get last entry in array
      const file = currentFiles.at(-1);
      console.log('currentDOWNLOAD FILE:', file);
      sendRequest(file.username, file.fileId, file.signedMessage, file.isPdf, file.isAnonymous, file.socketId);
    }
  }, [currentFiles]);

  useEffect(() => {
    //ADD SIGNED MESSAGE TO REF WITH UNIQUE KEY TO PREVENT DUPLICATES
    //COMBINE USERNAME AND FILE ID TO CREATE UNIQUE KEY
    signedMessageRef.current[signedMessage.key] = signedMessage.message;
  }, [signedMessage]);

  useEffect(() => {
    if(socket) {
      //SENDER SIDE - START DOWNLOAD PROCESS
      socket.on('start_download', async ({ sendTo, fileId, isPdf, isAnonymous, socketId }) => {
        console.log('start_download', sendTo, fileId, isPdf, isAnonymous, socketId);
        //GET FILE FROM LOCAL DB
        let transferFile;
        let file;
        let transferFileId;
        if(isAnonymous) {
          transferFile = await db.files.where({cid_public: fileId}).first();
          file = await decryptMessage(transferFile.file, transferFile.file.name, myUsername);
          transferFileId = transferFile.cid_public;
          console.log('DECRYPTED FILE', file);
        }else {
          if(isPdf) {
            transferFile = await db.files.where({cid_pdf: fileId}).first();
            file = transferFile.pdfFile;
          }else {
            transferFile = await db.files.where({cid_private: fileId}).first();
            file = transferFile.file;
          }
          transferFileId = transferFile.cid_private;
        }
        console.log('document', transferFile);
        
        //LOAD FILE INTO ARRAY BUFFER AND START PROCESS OF SENDING TO PEER
        var reader = new FileReader();
        reader.onload = async function(){
          var arrayBuffer = new Uint8Array(this.result);
          let key = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, myUsername);
          const signedMessage = await key.sign(arrayBuffer, myUsername, window.account.connection.networkId);
          // console.log('signedMessage', signedMessage);
          // console.log('file Array buffer', arrayBuffer);
          setCurrentFiles((prevState) => {
            // console.log('start_download prevState', prevState);
            return [...prevState, {username: sendTo, file: arrayBuffer, fileId: fileId, signedMessage: signedMessage, name: file.name, cid_contract: transferFile.cid_contract, isPdf: isPdf, isAnonymous: isAnonymous, socketId: socketId}];
          });
        }
        reader.readAsArrayBuffer(file);
        // setCurrentActivePage('current-transfers')
        let progress = {};
        progress['user'] = sendTo;
        progress['percentage'] = 0;
        progress['downloading'] = false;
        progress['fileName'] = file.name;
        progress['fileId'] = transferFileId;
        console.log('currentPercentage Download accordion', downloads);
        setCurrentTransfers((prevState) => {
          let newState = [...prevState];
          if(newState.length > 0) {
            const index = newState.findIndex(object => object.fileId === progress.fileId);
            if (index === -1) {
              newState = [...prevState, progress];
            } else {
              newState[index] = progress;
            }
          }else {
            newState = [...prevState, progress];
          }
          return [...newState];
        });
      });

      //RECEIVER SIDE - RECEIVE REQUEST
      socket.on('request_sent', ({ signal, to, username, fileMetadata, fileSize, signedMessage, isAnonymous, socketId}) => {
        console.log('request_sent', signal, username, fileMetadata, fileSize, signedMessage)
        setSignedMessage({message: signedMessage, key: username + fileMetadata.fileId});
        acceptRequest(signal, to, username, fileMetadata, fileSize, isAnonymous, socketId);
      });
      
      //SENDER SIDE - RECEIVE ACCEPTANCE
      socket.on('request_accepted', ({ signal, username, fileId }) => {
        console.log('request_accepted', signal, username, fileId);
        console.log('peerInstanceHost.current', peerInstanceHost.current);
        peerInstanceHost.current[username + fileId].signal(signal);
      });

      //CANCEL CURRENT DOWNLOAD
      socket.on('cancel_download', (data) => {
        console.log('cancel_download', data);
        console.log(peerInstanceHost.current);
        if(data.isDownloading) {
          //CHECK IF TRANSFER ALREADY STARTED
          if(peerInstanceHost.current[data.username]) {
            peerInstanceHost.current[data.myUsername].destroy();
            delete peerInstanceHost.current[data.myUsername];
          }
          setDownloads((prevState) => {
            return prevState.filter(el => el.fileId !== data.fileId);
          });
        }else {
          //CHECK IF TRANSFER ALREADY STARTED
          if(peerInstanceReceiver.current[data.fileId]) {
            peerInstanceReceiver.current[data.fileId].destroy();
          }
          console.log('currentTransfers' , currentTransfers);
          setCurrentTransfers((prevState) => {
            return prevState.filter(el => el.fileId !== data.fileId);
          });
        }
      });
    }
    return () => {
      if(socket) {
        socket.removeAllListeners("start_download");
        socket.removeAllListeners("request_sent");
        socket.removeAllListeners("request_accepted");
        socket.removeAllListeners("cancel_download");
      }
    }
  }, [socket]);


  //SENDER SIDE - SEND REQUEST
  const sendRequest = async (username, fileId, signedMessage, isPdf, isAnonymous, socketId) => {
    let transfered = 0;
    let transferFile;
    let file;

    if(isAnonymous) {
      transferFile = await db.files.where({cid_public: fileId}).first();
      file = await decryptMessage(transferFile.file, transferFile.file.name, myUsername);
    }else {
      if(isPdf) {
        transferFile = await db.files.where({cid_pdf: fileId}).first();
        file = transferFile.pdfFile;
      }else {
        transferFile = await db.files.where({cid_private: fileId}).first();
        file = transferFile.file;
      }
    }
    
    // let file = transferFile.file;
    // if(transferFile.pdfFile) {
    //   for( const user of transferFile.users) {
    //     const userWithPermissions = user.split('|');
    //     console.log('USER WITH PERMISSIONS: ', userWithPermissions);
    //     if(userWithPermissions[0] === username) {
    //       if(userWithPermissions[1][2] !== 't') {
    //         file = transferFile.pdfFile;
    //         cidPdf = transferFile.cid_pdf;
    //         isPdf = true;
    //         break;
    //       }
    //     }
    //   }
    // }
    console.log('SEND REQUEST: ', username, fileId, signedMessage);
    try {
      const peer = new window.SimplePeer({
        initiator: true,
        trickle: false,
        config: peerConfig
      });
      peer.on("signal", async data => {
        console.log('PEER SIGNAL: ', data);
        console.log('TRANSFER FILE: ', file);
        let fileMetadata;
        if(!isAnonymous) {
          const userWithPermissions = transferFile.users.find(el => el.split('|')[0] === username);
          const permissions = userWithPermissions.split('|')[1];
  
          fileMetadata = {
            type: file.type,
            name: file.name,
            fileId: fileId,
            cid_contract: transferFile.cid_contract,
            readPermissions: permissions[0] === 't' ? true : false,
            watermarkPermissions: permissions[1] === 't' ? true : false,
            rawPermissions: permissions[2] === 't' ? true : false
          };
        }else {
          fileMetadata = {
            type: transferFile.file.type,
            name: transferFile.file.name,
            fileId: fileId,
            cid_contract: transferFile.cid_contract,
            readPermissions: false,
            watermarkPermissions: false,
            rawPermissions: true,
          };
        }
        socket.emit('send_request', {
          to: username,
          signal: data,
          username: myUsername,
          fileMetadata: fileMetadata,
          fileSize: file.size,
          signedMessage: signedMessage,
          isAnonymous: isAnonymous,
          socketId: socketId
        });
      });
      peer.on("connect", async () => {
        const file = currentFiles.find(el => el.fileId === fileId);
        console.log('FILE ID: ', fileId);
        console.log('FILE: ', file);
        let buffer = file.file;
        let chunkSize = 65535;
        if(buffer.byteLength < chunkSize) {
          chunkSize = buffer.byteLength;
        }
        await sendChunks(peerInstanceHost.current[username + fileId], fileId, buffer, chunkSize, file.file.byteLength, transfered, username);
      });
      console.log('SET sendrequest PEER: ', username + fileId);
      //Combine username and fileId to create unique key for current transfer
      peerInstanceHost.current[username + fileId] = peer;
    }catch (err) {
      console.log('SIMPLEPEER ERROR: ', err);
    }
  };

  //RECEIVER SIDE - ACCEPT REQUEST
  const acceptRequest = async (signal, to, username, fileMetadata, fileSize, isAnonymous, socketId) => {
    console.log('ACCEPT REQUEST: ', signal, to, username, fileMetadata, fileSize, isAnonymous, socketId);
    const peer = peerInstanceReceiver.current[fileMetadata.fileId];
    if(peer.destroyed) {
      return;
    }
    console.log('peer', peerInstanceReceiver.current);
    peer.on("signal", data => {
      socket.emit('accept_request', {
        signal: data,
        to: username,
        myUsername: to,
        fileId: fileMetadata.fileId,
        isAnonymous: isAnonymous,
        socketId: socketId
      });
    });
    const fileChunks = [];
    let transfered = 0;
    peer.on("data", async data => {
      let progress = {};
      progress['user'] = username;
      progress['percentage'] = Math.floor((transfered / fileSize) * 100);
      progress['downloading'] = true;
      progress['verified'] = false;
      progress['fileName'] = fileMetadata.name;
      progress['fileId'] = fileMetadata.fileId;
      progress['rawPermissions'] = fileMetadata.rawPermissions;
      progress['readPermissions'] = fileMetadata.readPermissions;
      progress['watermarkPermissions'] = fileMetadata.watermarkPermissions;
      progress['statusMessage'] = 'Downloading...';
      //END OF FILE TRANSFER
      if (data.toString() === "EOF") {
        console.log('EOF');
        // Once, all the chunks are received, combine them to form a Blob
        const receivedFile = new Blob(fileChunks, { type: fileMetadata.type });
        var reader = new FileReader();
        reader.onload = async function() {
          //VERIFY FILE
          const encodedMessage = new Uint8Array(this.result);
          //check if file is correctly signed
          // console.log('ENCODED MESSAGE: ', encodedMessage);

          console.log('SIGNED MESSAGE: ', signedMessageRef.current[username + fileMetadata.fileId]);

          const signedMessage = {signature: new Uint8Array(signedMessageRef.current[username + fileMetadata.fileId].signature), publicKey: {keyType: signedMessageRef.current[username + fileMetadata.fileId].publicKey.keyType ,data: new Uint8Array(signedMessageRef.current[username + fileMetadata.fileId].publicKey.data)}};
          const publicKey = new PublicKey(signedMessage.publicKey);
          const verified = publicKey.verify(encodedMessage, signedMessage.signature);
          console.log('VERIFIED: ', verified)
          if(verified) {
            progress['verified'] = true;
            progress['file'] = receivedFile;
            // await db.downloads.add({
            //   name: progress['fileName'],
            //   size: fileSize,
            //   sharedTo: [username],
            //   fileId: fileMetadata.fileId,
            //   date: new Date().getTime(),
            // });

            let metricPermissions = [];
            if(fileMetadata.rawPermissions) {
              metricPermissions.push('raw');
            }
            if(fileMetadata.readPermissions) {
              metricPermissions.push('read');
            }
            if(fileMetadata.watermarkPermissions) {
              metricPermissions.push('watermark');
            }
            const data = {
              name: progress['fileName'],
              size: fileSize,
              sharedTo: myUsername ? myUsername : to,
              sharedBy: username,
              date: new Date().getTime(),
              permissions: metricPermissions,
              transferType: 'P2P',
              action: 'Transfer'
            }
            sendMetrics(data);

            if(!isAnonymous) {
              const fileExists = await db.downloadedFiles.get({fileId: progress.fileId});
              console.log('fileExists', fileExists);
              //SAVE FILE TO LOCAL DB SO THAT IT CAN BE ACCESSED ON REFRESH
              if(fileExists) {  
                await db.downloadedFiles.update(fileExists.id, {
                  file: receivedFile,
                  fileName: progress['fileName'],
                  size: fileSize,
                  owner: progress.user,
                  fileId: progress.fileId,
                  date: new Date().getTime(),
                  cid_contract: fileMetadata.cid_contract,
                  rawPermissions: fileMetadata.rawPermissions,
                  readPermissions: fileMetadata.readPermissions,
                  watermarkPermissions: fileMetadata.watermarkPermissions,
                });
              }else {
                await db.downloadedFiles.add({
                  file: receivedFile,
                  fileName: progress['fileName'],
                  size: fileSize,
                  owner: progress.user,
                  fileId: progress.fileId,
                  date: new Date().getTime(),
                  cid_contract: fileMetadata.cid_contract,
                  rawPermissions: fileMetadata.rawPermissions,
                  readPermissions: fileMetadata.readPermissions,
                  watermarkPermissions: fileMetadata.watermarkPermissions,
                });
              }
            }
            
          } else {
            progress['verified'] = false;
            console.log('INVALID');
          }
          signedMessageRef.current[username + fileMetadata.fileId] = null;
          progress['statusMessage'] = 'Verified!';
          setDownloads((prevState) => {
            let newState = [...prevState];
            if(newState.length > 0) {
              const index = newState.findIndex(object => object.fileId === progress.fileId);
              if (index === -1) {
                newState = [...prevState, progress];
              } else {
                newState[index] = progress;
              }
            }
            return [...newState];
          });

          peerInstanceReceiver.current[fileMetadata.fileId].destroy();
          delete peerInstanceReceiver.current[fileMetadata.fileId];
        }
        reader.readAsArrayBuffer(receivedFile);
       
      } else {
        transfered += data.length;
        progress['statusMessage'] = 'Downloading...';
        setDownloads((prevState) =>  {
          let newState = [...prevState];
          if(newState.length > 0) {
            const index = newState.findIndex(object => object.fileId === progress.fileId);
            if (index === -1) {
              newState = [...prevState, progress];
            } else {
              newState[index] = progress;
            }
          }
          return [...newState];
        });
        // Keep appending various file chunks
        fileChunks.push(data);
      }
    });
    console.log('PEER SIGNAL: ', signal);
    peer.signal(signal);
  };

  //SENDER SIDE HELPER FUNCTION
  const sendChunks = async (peer, fileId, buffer, chunkSize, fileSize, transfered, sendingTo) => {
    while (buffer.byteLength) {
      if (peer._channel.bufferedAmount > peer._channel.bufferedAmountLowThreshold) {
        // eslint-disable-next-line no-loop-func
        peer._channel.onbufferedamountlow = () => {
          peer._channel.onbufferedamountlow = null;
          sendChunks(peer, fileId, buffer, chunkSize, fileSize, transfered, sendingTo);
        };
        return;
      }
      const chunk = buffer.slice(0, chunkSize);
      buffer = buffer.slice(chunkSize, buffer.byteLength);
      peer.send(chunk);
      transfered += chunkSize;
      let progress = {};
      progress['user'] = sendingTo;
      const percentage = Math.floor((transfered / fileSize) * 100);
      progress['percentage'] =  percentage > 100 ? 100 : percentage;
      progress['fileName'] = currentFiles.find(el => el.fileId === fileId).name;
      progress['fileId'] = fileId;
      progress['downloading'] = false;
      setCurrentTransfers((prevState) => {
        let newState = [...prevState];
        if(newState.length > 0) {
          const index = newState.findIndex(object => object.fileId === progress.fileId);
          if (index === -1) {
            newState = [...prevState, progress];
          } else {
            newState[index] = progress;
          }
        }
        return [...newState];
      });
    }
    peer.send("EOF");
    console.log('FILE SENT');
    // await db.uploads.add({
    //   name: currentFiles.find((el) => el.username === sendingTo).name,   
    //   type: currentFiles.find((el) => el.username === sendingTo).type,
    //   size: currentFiles.find((el) => el.username === sendingTo).file.byteLength,
    //   sharedTo: [sendingTo],
    //   sharedBy: myUsername,
    //   fileId: currentFiles.find((el) => el.username === sendingTo).fileId,
    //   date: new Date().getTime(),
    // });
    // console.log('CURRENT FILES: ', currentFiles);
   
    setCurrentTransfers((prevState) => {
      console.log('PREV STATE: ', prevState);
      return prevState.filter(el => el.fileId !== fileId );
    });
  };
  return (
    <TransferFileContext.Provider value={{}}>
      {children}
    </TransferFileContext.Provider>
  );
}