import { createContext, useContext, useEffect, useState } from "react";
import { useGlobalContext } from "./GlobalProvider";
import { db } from "../inc/database";
import { useUserFilesContext } from "./UserFilesProvider";
import { decryptPrivateKey } from "../inc/encryption";
import nacl from "tweetnacl";
import { io } from 'socket.io-client';

const SocketContext = createContext();

export const useSocketContext = () => { useContext(SocketContext) };

export default function SocketEventsProvider({ children }) {
  const { socket, setSocket, myUsername, setOpenKeyModal, setIsKeyError } = useGlobalContext();
  const { setUploadedFiles, setDownloads, availableFiles, setAvailableFiles, peerInstanceReceiver } = useUserFilesContext();

  useEffect(() => {
    if(!socket) {
      initSocketConnection();
    }
    if(socket) {
      socket.on('connected', (data) => {
        console.log('connected', data);
        if(!data.isAnonymous) {
          decryptPrivateKey().then((decryptedKeyPair) => {
            if(decryptedKeyPair === null) {
              console.log('No private key found');
              setIsKeyError('The current secret key cannot be decrypted. Please generate a new secret key or upload the correct secret key file.')
              setOpenKeyModal(true);
              return;
            }
            console.log('decryptedKeyPair', decryptedKeyPair);
            const publicKey = nacl.util.encodeBase64(decryptedKeyPair.publicKey)
            console.log('publicKey', publicKey);
            socket.emit('init', {username: myUsername, publicKey: publicKey});
            //GET SHARED FILES FROM DB
            getCurrentFiles();
          });
        }
      });

      socket.on('private_key', async (data) => {
        console.log('init priv key');
        // window.account.connection.signer.keyStore.setKey(window.account.connection.networkId, myUsername, data.privateKey);
      });

      //UPDATE SHARED FILE LIST ON INIT
      socket.on('update_shared_files', data => {
        console.log('update_shared_files', data);
        if(data.sharedFiles.length > 0) {
          setAvailableFiles([...data.sharedFiles]);
        }
      });

      //UPDATE SHARED FILE LIST ON GIVEN ACCESS
      socket.on('granted_access', async (data) => {
        console.log('granted_access', data)
        // console.log('currentPercentage', currentPercentage);
        if(data.share) {
          setAvailableFiles((prevState) => {
            const removedOldFile = prevState.filter((doc) => doc.fileId !== data.document.fileId);
            return [data.document, ...removedOldFile];
          });


          //Remove file from downloaded file if it was changed
          const dbFile = await db.downloadedFiles.toArray();
          console.log('dbFile', dbFile);
          console.log('data cid_contract', data.cid_contract);
          for(const file of dbFile) {
            console.log('file cid_contract', file.cid_contract);
            if(file.cid_contract === data.cid_contract) {
              console.log('DELETING FILE');
              await db.downloadedFiles.delete(file.id);
            }
          }

          const allDownloadedFiles = await db.downloadedFiles.toArray();
          const currentDownloadedFiles = allDownloadedFiles.map((file) => {
            const fileData = {
              verified: true,
              downloading: true,
              anonymous: false,
              percentage: 100,
              user: file.owner,
              name: file.fileName,
            };
            return {...file, ...fileData};
          });
          console.log('allDownloadedFiles', currentDownloadedFiles);
          // setDownloads(currentDownloadedFiles);
          setDownloads([...currentDownloadedFiles]);

          // if(dbFile) {
          //   if(dbFile.rawPermissions !== data.rawPermissions) {
          //     await db.downloadedFiles.delete(dbFile.id);
          //   }
          //   if(dbFile.readPermissions !== data.readPermissions) {
          //     await db.downloadedFiles.delete(dbFile.id);
          //   }
          // }
          
          // setCurrentPercentage((prevState) => {
          //   const file = prevState.find((doc) => doc.fileId === data.old_cid_private && doc.fileId === data.old_cid_pdf);
          //   console.log('Found file', file);
          //   console.log('file', file);
          //   if(file) {
          //     if(file.fileName !== data.document.name) {
          //       const newState = prevState.filter((doc) => doc.fileId !== data.old_cid_private && doc.fileId !== data.old_cid_pdf);
          //       return [...newState];
          //     }else {
          //       const updatedFile = {...file};
          //       const newState = prevState.filter((doc) => doc.fileId === data.old_cid_private || doc.fileId === data.old_cid_pdf);
          //       updatedFile.fileId = data.document.fileId;
          //       // updatedFile.permissions = data.document.permissions;
          //       return [updatedFile, ...newState];
          //     }
          //   }
          //   return [...prevState];
          // });
        }else {
          setUploadedFiles((prevState) => {
            const newDocs = prevState.map((doc) => {
              if(doc.fileId == data.document.fileId) {
                data.document.id = doc.id;
                return data.document;
              }
              return doc;
            });
            return [...newDocs];
          });
        }
      });

      //UPDATE FILE LIST ON DOCUMENT DELETE
      socket.on('deleted_document', async (data) => {
        console.log('deleted_document', data);
        if(data.share) {
          setDownloads((prevState) => 
            prevState.filter((doc) => doc.fileId !== data.fileId)
          );
          setAvailableFiles((prevState) =>
            prevState.filter((doc) => doc.fileId !== data.fileId)
          );
        }else {
          setUploadedFiles((prevState) => 
            prevState.filter((doc) => String(doc.fileId) !== data.fileId)
          );
        }
      });

      //UPDATE SHARED FILE LIST ON REVOKED ACCESS
      socket.on('revoked_access', async (data) => {
        console.log('revoked_access', data)
        //IF FILE IS SHARED WITH ME
        if(data.share) {
          setAvailableFiles((prevState) => {
            // const newState = prevState.filter((doc) => doc.fileId !== data.fileId)
            const newState = prevState.filter((doc) => doc.cid_contract !== data.cidContract)
            return newState;
          });

          const dbFile = await db.downloadedFiles.toArray();
          console.log('dbFile', dbFile);
          for(const file of dbFile) {
            if(file.cid_contract === data.cidContract) {
              await db.downloadedFiles.delete(file.id);
            }
          }
          const allDownloadedFiles = await db.downloadedFiles.toArray();
          const currentDownloadedFiles = allDownloadedFiles.map((file) => {
            const fileData = {
              verified: true,
              downloading: true,
              anonymous: false,
              percentage: 100,
              user: file.owner,
              name: file.fileName,
            };
            return {...file, ...fileData};
          });
          console.log('allDownloadedFiles', currentDownloadedFiles);
          // setDownloads(currentDownloadedFiles);
          //TODO FILTER PER FILE STATUS (DOWNLOAD/UPLOAD)
          setDownloads((prevState) => {
            const newState = [...currentDownloadedFiles]
            return newState;
          });

        }else {
          if(data.refresh) {
            setUploadedFiles((prevState) => {
              let newState = prevState.filter((doc) => doc.fileId !== data.fileId)
              newState = [...newState, data.document];
              return newState;
            });

            const dbFile = await db.downloadedFiles.toArray();
            console.log('dbFile', dbFile);
            for(const file of dbFile) {
              if(file.cid_contract === data.cid_contract) {
                await db.downloadedFiles.delete(file.id);
              }
            }
  
            const allDownloadedFiles = await db.downloadedFiles.toArray();
            const currentDownloadedFiles = allDownloadedFiles.map((file) => {
              const fileData = {
                verified: true,
                downloading: true,
                anonymous: false,
                percentage: 100,
                user: file.owner
              };
              return {...file, ...fileData};
            });
            console.log('allDownloadedFiles', currentDownloadedFiles);
            setDownloads(currentDownloadedFiles);
          }else {
            //IF FILE IS SHARED BY ME
            const files = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
            console.log('newDocumentsList', files);
            setUploadedFiles([...files]);
          }
        }
      });

      //SIGNAL FOR CHECKING IF FILE IS SYNCED BETWEEN DB AND OWNER
      socket.on('files_synced', (data) => {
        console.log('files_synced', data);
        if(data.removingFile) {
          setAvailableFiles((prevState) =>
            prevState.filter((doc) => doc.fileId !== document.fileId)
          );
        }else {
          setAvailableFiles((prevState) => {
            return [...prevState, data.document];
          });
        }
      });

      //UPDATE ONLINE USERS LIST
      socket.on('users_list', data => {
        console.log('users_list', data)
        console.log('availableFiles', availableFiles)
        setAvailableFiles((prevState) => {
          let newState = [];
          for(let [i, file] of prevState.entries()) {
            const currentUser = {...file};
            const owner = file.owner;
            const isOnline = data.find(user => user.username === owner);
            if(isOnline) {
              currentUser.ownerOnline = true;
            }else {
              currentUser.ownerOnline = false;
            }
            newState.push(currentUser)
          }
          return [...newState];
        });
      });

      // socket.on('cancel_transfers_on_disconnect', data => {
      //   console.log('cancel_transfers_on_disconnect', data);
      //   setCurrentTransfers((prevState) => {
      //     const newState = prevState.filter((file) => file.user === data.user);
      //     return newState;
      //   });
      // });
    }

    return () => {
      console.log('SOCKET CLEANUP UI')
      if(socket) {
        socket.removeAllListeners("update_shared_files");
        socket.removeAllListeners("granted_access");
        socket.removeAllListeners("deleted_document");
        socket.removeAllListeners("revoked_access");
        socket.removeAllListeners("file_synced");
        socket.removeAllListeners("users_list");
      }
    }
  }, [socket]);

  const initSocketConnection = () => {
    const initSocket = io.connect(process.env.REACT_APP_SERVER_SOCKET_URL, {
      path: process.env.REACT_APP_SOCKET_PATH,
      transports: ["websocket"],
      removeTrailingSlashes: true,
      query: {
        token: localStorage.getItem('jwtToken'),
        isAnonymous: false
      }
    });
    initSocket.on("exception", (err) => {
      console.log('SOCKET ERROR: ', err); // prints the message associated with the error
    });
    initSocket.on("error", (msg) => {
      if(msg.status === 0 && msg.message === 'User not found') {
        alert('Your session has expired. Please login again.');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('username');
        window.location.href = '/';
      }
    });
    setSocket(initSocket);
    
    console.log('SOCKET INITIALIZED');
  };

  //Check database for current user files - INIT FUNCTION
  const getCurrentFiles = async () => {
    const documents = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
    console.log('CURRENT DOCUMENTS IN LOCAL DB of owner:', documents, myUsername);
    if(documents) {
      let currentDocuments = [];
      documents.forEach((document) => {
        currentDocuments.push({
          fileId: document.fileId,
          name: document.file.name,
          size: document.file.size,
          type: document.file.type,
          owner: document.owner,
          date: document.date,
          users: document.users,
          id: document.id,
          cid_private: document.cid_private ? document.cid_private : '',
          cid_pdf: document.cid_pdf ? document.cid_pdf : '',
          cid_contract: document.cid_contract ? document.cid_contract : '',
          cid_public: document.cid_public ? document.cid_public : '',
        });
      })
      setUploadedFiles(currentDocuments);
      console.log('sync_files');
      socket.emit('sync_files', {documents: currentDocuments, username: myUsername});
    }
  };

  // //START DOWNLOAD PROCESS FOR DOCUMENT
  // const downloadDocumentP2P = async (fileId, owner, name, isPdf = null) => {
  //   console.log('Download document P2P: ', fileId, owner, name);
  //   let progress = {};
  //   progress['user'] = owner;
  //   progress['percentage'] = 0;
  //   progress['downloading'] = true;
  //   progress['verified'] = false;
  //   progress['fileName'] = name;
  //   progress['fileId'] = fileId;

  //   setDownloads((prevState) => {
  //     let newState = [...prevState];
  //     if(newState.length > 0) {
  //       const index = newState.findIndex(object => object.fileId === progress.fileId);
  //       if (index === -1) {
  //         newState = [...prevState, progress];
  //       } else {
  //         newState[index] = progress;
  //       }
  //     }else {
  //       newState = [...prevState, progress];
  //     }
  //     return [...newState];
  //   });

  //   // setOpenDownloadModal(true);
  //   // setAnimateFlip(true);

  //   console.log('current fileID', fileId)
  //   //TODO disable multiple instances of same file - save file in DB
  //   const peer = new window.SimplePeer({
  //     initiator: false,
  //     trickle: false,
  //     config: peerConfig
  //   });
  //   peerInstanceReceiver.current[fileId] = peer;
  //   socket.emit('init_download', {fileId: fileId, username: myUsername, isPdf: isPdf});
  // };

  // const downloadDocumentIPFS = async (cid, owner, fileName ) => {
  //   console.log('Download document IPFS: ', cid, owner, fileName);

  //   const sharedFile = availableFiles.find((file) => file.cid_private === cid || file.cid_pdf === cid);
  //   console.log('sharedFile', sharedFile)
  //   const newProgress = {};
  //   newProgress['fileName'] = fileName;
  //   newProgress['fileId'] = cid;
  //   newProgress['percentage'] = 0;
  //   newProgress['downloading'] = true;
  //   newProgress['verified'] = false;
  //   newProgress['anonymous'] = false;
  //   newProgress['user'] = owner;
  //   newProgress['rawPermissions'] = sharedFile['rawPermissions'];
  //   newProgress['readPermissions'] = sharedFile['readPermissions'];
  //   newProgress['watermarkPermissions'] = sharedFile['watermarkPermissions'];
    
  //   // console.log('currentSharedFiles', currentSharedFiles)
  //   // console.log('fileId', cid)
  //   console.log('newProgress', newProgress)

  //   setDownloads((prevState) => {
  //     let newState = [...prevState];
  //     if(newState.length > 0) {
  //       const index = newState.findIndex(object => object.fileId === newProgress.fileId);
  //       if (index === -1) {
  //         newState = [...prevState, newProgress];
  //       } else {
  //         newState[index] = newProgress;
  //       }
  //     }
  //     console.log('newState download private document', newState);
  //     return [...newState];
  //   });
  //   // setIpfsDownload(cid);
  //   // setOpenDownloadModal(true);
  //   // setAnimateFlip(true);
  // };

  return (
    <SocketContext.Provider value={{ availableFiles, setDownloads, setAvailableFiles, setDownloads, peerInstanceReceiver  }}>
      {children}
    </SocketContext.Provider>
  );
}