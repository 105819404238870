import cancelButton from '../assets/images/cancel-x.png';
import styled from "styled-components";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import { useState } from 'react';
import { downloadFileFromIPFS } from '../inc/storage';
import { decryptUserFile } from '../inc/encryption';
import { useGlobalContext } from '../context/GlobalProvider';
import { db } from '../inc/database';
import { useUserFilesContext } from '../context/UserFilesProvider';

const ModalWrapper = styled.div`
    background: var(--BG_8, #283134);
    box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
    border-radius: 32px;
    padding: 24px; 
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .modal-title-wrap {

        .title {
          color: #FFF;
          font-family: Open Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 155%;
        }
        .cancel-icon {
          width: 24px;
          height: 24px;
        }
      }
    }

    .progress-bar-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 10px;
    
      .progress-bar {
        width: 100%;
        height: 10px;
        background: transparent;
        border-radius: 10px;
      }
    
      .percentage {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 15px;
        display: flex;
        align-items: center;
        background: transparent;
        margin-left: 5px !important;
      }
    }

    .subtitle {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
    }

    .message {
      color: #30BD5C;
      margin-bottom: 24px;
    }

    .modal-footer {
      justify-content: center;
    }
  `;


export default function SyncFilesModal({ handleClose }) {

  const [progressBarData, setProgressBarData] = useState({message: '', percentage: 0});
  const { myUsername, socket } = useGlobalContext();
  const { setUploadedFiles } = useUserFilesContext();

  const syncFiles = async () => {

    await getMyFilesBC();
    // setProgressBarData({message: 'Getting shared files from smart contract...', percentage: 70});
    console.log('getSharedFilesBC')
    //TODO uncomment when removing DB - only smart contract
    // const sharedFiles = await getSharedFilesBC();
    setProgressBarData({message: 'Files synchronized.', percentage: 100});
    // handleClose(false);
    // console.log('sharedFiles', sharedFiles);
    // if(sharedFiles.length > 0) {
    //   setProgressBarData({message: 'Files added, click close to continue.', percentage: 100});
    //   setCurrentSharedFiles([...sharedFiles]);
    // }else {
    //   setProgressBarData({message: 'No shared files found...', percentage: 100});
    // }
  };

  //Check smart contract for current user files - INIT FUNCTION
  const getMyFilesBC = async () => {
    setProgressBarData({message: 'Downloading Files', percentage: 10});

    console.log('getMyFilesBC')
    const ipfsCID = await window.contract.get_ipfs_for_wallet({wallet_id: window.walletConnection.getAccountId()});
    console.log('files', ipfsCID);

    if(ipfsCID) {
      setProgressBarData({message: 'Downloading Files', percentage: 40});
      const fileObject = await downloadFileFromIPFS(ipfsCID);
      console.log('fileObject', fileObject);
      
      const fileReader = new FileReader();
      fileReader.readAsText(fileObject.file);
      const fileContent = await new Promise((resolve) => {
        fileReader.onload = (e) => {
          const fileContent = JSON.parse(e.target.result);
          resolve(fileContent);
        };  
      });
      console.log('fileContent', fileContent)
      const parsedJson = await decryptUserFile(fileContent, myUsername);
      console.log('parsedJson', parsedJson);
      let tmpMyFiles = [];
      for(let file of parsedJson) {
        console.log('file', file);
        let userFile = null;
        //TODO check if file only has public cid
        if(file.cid_private) {
          userFile = await downloadFileFromIPFS(file.cid_private);

          let pdfFile = null;
          if(file.cid_pdf) {
            let rawPdfFile = await downloadFileFromIPFS(file.cid_pdf);
            pdfFile = rawPdfFile.file;
          }
          tmpMyFiles.push({...file, file: userFile.file, pdfFile: pdfFile});
        }
        // else {
        //   userFile = await downloadFileFromIPFS(file.cid_public);
        // }
        
      }
      setProgressBarData({message: 'Syncing Files with Database', percentage: 70});
      const currentFiles = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
      console.log('currentFiles', currentFiles);

      const newFiles = tmpMyFiles.filter(object1 => {
        return !currentFiles.some(object2 => {
          return object1.cid_private === object2.cid_private || object1.fileId === object2.fileId;
        });
      });

      if(newFiles.length > 0) {
        await db.files.bulkAdd(newFiles);
      }
      
      const allUserFiles = await db.files.where({owner: myUsername}).reverse().sortBy('fileId');
      let currentDocuments = [];
      allUserFiles.forEach((document) => {
        currentDocuments.push({
          fileId: document.fileId,
          name: document.file.name,
          size: document.file.size,
          type: document.file.type,
          owner: document.owner,
          date: document.date,
          users: document.users,
          id: document.id,
          cid_private: document.cid_private ? document.cid_private : null,
          cid_pdf: document.cid_pdf ? document.cid_pdf : null,
          cid_contract: document.cid_contract ? document.cid_contract : null,
          cid_public: document.cid_public ? document.cid_public : null,
        });
      })
      setUploadedFiles([...currentDocuments]);
      socket.emit('sync_files', {documents: currentDocuments, username: myUsername});
      setProgressBarData({message: 'Sync complete', percentage: 100});
    }else {
      setProgressBarData({message: 'Sync complete', percentage: 100});
    }
  };

  const fillerStyles = {
    height: '100%',
    width: `${progressBarData.percentage}%`,
    background: '#7FD971',
    borderRadius: '10px',
  };

  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="xs" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <div className="modal-title-wrap">
            <p className="title">Sync files between devices</p>
            <p className="subtitle">Break free from device boundaries. Retrieve your files from your decentralized cloud (blockchain smart contract)</p>
          </div>
          <button className="close-btn" onClick={() => handleClose()} >
            <img className="cancel-icon" src={cancelButton} alt="cancel button" />
          </button>
        </DialogHeader>
        <DialogBody>
          <div className="progress-bar-wrap">
            <div className="progress-bar">
              <p style={fillerStyles}></p>
            </div>
          </div>
          <p className="message">{progressBarData.message}</p>
          <button className="btn-primary" onClick={() => syncFiles()}>
            Sync files
          </button>
        </DialogBody>
      </ModalWrapper>
    </Dialog>
  )
}