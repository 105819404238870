import * as bs58 from 'bs58';
import nacl from 'tweetnacl';
import { db } from './database';
import crypto from 'crypto-browserify';
import { encode } from '@stablelib/base64';
nacl.util = require('tweetnacl-util');

function encodeUint8ArrayToBase64(uint8Array) {
  const chunkSize = 1024 * 1024; // Define a chunk size (e.g., 1MB)
  let base64String = '';

  for (let i = 0; i < uint8Array.length; i += chunkSize) {
    // Get a chunk of the Uint8Array
    const chunk = uint8Array.subarray(i, i + chunkSize);

    // Convert the chunk to a binary string
    let binaryString = '';
    for (let j = 0; j < chunk.length; j++) {
      binaryString += String.fromCharCode(chunk[j]);
    }

    // Encode the binary string to base64 and append to the result
    base64String += btoa(binaryString);
  }

  return base64String;
}


export const encryptFile = async (doc, recipientPublicKeys, username, isUpload = false) => {

  // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, username);
  // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
  // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));
  const hostKeyPair = await decryptPrivateKey();

  //GET FILE FROM DB
  // const doc = await db.files.get(fileId);
  let fileContent = null;
  let symmetricKey = null;
  let parsedFileWithMetadata = {};
  let messageNonce = null;
  let fileContentUi8 = null;
  let encryptedMessage = null;
  

  //CHECK IF WE ARE UPDATING USERS OR IF WE ARE SHARING FOR THE FIRST TIME
  if(!isUpload) {
    const fileReader = new FileReader();
    fileReader.readAsText(doc.slice(0, doc.size, 'application/json'));
    fileContent = await new Promise((resolve) => {
      fileReader.onload = (e) => {
        parsedFileWithMetadata = JSON.parse(e.target.result);
        const privKey = hostKeyPair.secretKey;
        //FIND RIGHT ENCRYPTED SYMMETRIC KEY
        const encSymKey = parsedFileWithMetadata.metadata.encryptedSymmetricKeys.find(x => x.user === username);
        //DECODE NONCE
        const nonce = nacl.util.decodeBase64(encSymKey.nonce);
        //DECODE AND CAST TO UINT8ARRAY ENCRYPTED SYMMETRIC KEY
        const encryptedSymmetricKeyUint8 = new Uint8Array(nacl.util.decodeBase64(encSymKey.key));
        //CHECK IF WE ARE GRANTING OR REVOKING ACCESS IF WE ARE REVOKING WE GENERATE A NEW SYMMETRIC KEY
        symmetricKey = nacl.box.open(encryptedSymmetricKeyUint8, nonce, nacl.util.decodeBase64(parsedFileWithMetadata.metadata.senderPublicKey), privKey);

        //SET MESSAGE NONCE
        messageNonce = parsedFileWithMetadata.metadata.messageNonce;

        console.log('symmetricKey', symmetricKey);
        console.log('messageNonce', nacl.util.decodeBase64(messageNonce));
        console.log('parsedFileWithMetadata', parsedFileWithMetadata);
        console.log('FILE CONTENT', parsedFileWithMetadata.content);
        console.log('FILE CONTENT parsed', nacl.util.decodeBase64(parsedFileWithMetadata.content));
        const decryptedMessage = nacl.secretbox.open(nacl.util.decodeBase64(parsedFileWithMetadata.content), nacl.util.decodeBase64(messageNonce), symmetricKey);
        console.log('decryptedMessage', decryptedMessage);
        //RETURN BASE64 ENCODED FILE CONTENT
        resolve(decryptedMessage);
      };
    });
    // Convert the file content to Uint8Array format
    fileContentUi8 = fileContent;
  }else {
    //READ FILE CONTENT
    const reader = new FileReader();
    reader.readAsArrayBuffer(doc);
    fileContent = await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
    });

    // Convert the file content to Uint8Array format
   fileContentUi8 = new Uint8Array(fileContent);
  }

  // console.log('fileContent', fileContent);

  // Generate a random symmetric key
  symmetricKey = nacl.randomBytes(nacl.secretbox.keyLength);

  // console.log('symmetricKey', symmetricKey);

  // Generate a random nonce for use with AES encryption
  messageNonce = nacl.randomBytes(nacl.secretbox.nonceLength);

  // console.log('messageNonce', messageNonce);
  
  // Encrypt the file content using the symmetric key and nonce
  encryptedMessage = nacl.secretbox(fileContentUi8, messageNonce, symmetricKey);

  console.log('encryptedMessage', encryptedMessage);
  // Assuming uint8Array is your Uint8Array
  //TODO!!!!! 
  //   const base64Data = encodeUint8ArrayToBase64(encryptedMessage);
  // console.log('base64Data', base64Data);
  //   return;
  encryptedMessage = nacl.util.encodeBase64(encryptedMessage);

  //ENCODE NONCE
  messageNonce = nacl.util.encodeBase64(messageNonce);

  //ADD HOST PUBLIC KEY TO RECIPIENT PUBLIC KEYS SO THAT HE CAN DECRYPT THE SYMMETRIC KEY - FOR UPDATING FILE LATER ON
  recipientPublicKeys.push({publicKey: nacl.util.encodeBase64(hostKeyPair.publicKey), value: username});

  // Derive a shared secret for each recipient using the sender's private key and the recipient's public key
  let sharedKeys = recipientPublicKeys.map(publicKey => {
    const nonce = nacl.randomBytes(nacl.secretbox.nonceLength);
    const publicKeyBytes = nacl.util.decodeBase64(publicKey.publicKey);
    const encodedKey = nacl.box(symmetricKey, nonce, publicKeyBytes, hostKeyPair.secretKey);
    return {key: nacl.util.encodeBase64(encodedKey), nonce: nacl.util.encodeBase64(nonce), user: publicKey.value};
  });

  //IF ITS FILE UPDATE THEN ADD NEW SHARED KEYS TO THE EXISTING ONES
  if(parsedFileWithMetadata.metadata) {
    sharedKeys = [...sharedKeys];
  }

  //METADATA NEEDED FOR DECRYPTION
  const fileMetadata = {
    encryptedSymmetricKeys: sharedKeys,
    senderPublicKey: nacl.util.encodeBase64(hostKeyPair.publicKey),
    messageNonce: messageNonce
  };

  //COMBINE METADATA AND FILE
  const metadataAndFile = {
    metadata: fileMetadata,
    content: encryptedMessage
  };
  console.log('metadataAndFile', metadataAndFile);

  //CREATE BLOB CONTAINING METADATA AND FILE
  const metadataAndContentBlob = new Blob([JSON.stringify(metadataAndFile)], {type: doc.type});
  //CREATE FILE FROM BLOB CONTAINING METADATA AND FILE
  const fileWithMetadata = new File([metadataAndContentBlob], doc.name, {type: doc.type});

  return fileWithMetadata;
}

export const encryptUserFile = async (username, oldShareFileId = null) => {
  // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, username);
  // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
  // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));
  const nonce = nacl.randomBytes(nacl.secretbox.nonceLength);
  const hostKeyPair = await decryptPrivateKey();
  // console.log('hostKeyPair: ', hostKeyPair);

  let allFiles = await db.files.where({owner: username}).toArray();
  if(oldShareFileId) {
    allFiles = allFiles.filter(file => file.id !== oldShareFileId);
  }
  // console.log('allFiles', allFiles);
  // console.log(JSON.stringify(allFiles));
  const message = new TextEncoder().encode(JSON.stringify(allFiles));
  // console.log('USER message: ', message);

  const encryptedMessage = nacl.secretbox(message, nonce, hostKeyPair.publicKey);

  // console.log('encryptedMessage USER: ', encryptedMessage);
  //METADATA NEEDED FOR DECRYPTION
  const metadataAndFile = {
    metadata: {
      nonce: nacl.util.encodeBase64(nonce)
    },
    content: nacl.util.encodeBase64(encryptedMessage)
  };

  //CREATE BLOB CONTAINING METADATA AND FILE
  const metadataAndContentBlob = new Blob([JSON.stringify(metadataAndFile)], { type: 'application/json' });
  //CREATE FILE FROM BLOB CONTAINING METADATA AND FILE
  const fileWithMetadata = new File([metadataAndContentBlob], 'file.json', { type: 'application/json' });
  console.log('allFilesEncrypted: ', fileWithMetadata);

  return fileWithMetadata;
};

export const decryptMessage = async (file, fileName, myUsername) => {
  console.log('decryptMessage', file, fileName, myUsername);

  //GET HOST PRIVATE KEY
  // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, myUsername);
  // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
  // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));
  const hostKeyPair = await decryptPrivateKey();

  console.log('hostKeyPair', hostKeyPair);
  const metadataReader = new FileReader();
  //READ FILE CONTENT AND METADATA
  metadataReader.readAsText(file.slice(0, file.size, 'application/json'));
  const newFile = await new Promise((resolve) => {
    metadataReader.onload = (e) => {
      const result = JSON.parse(e.target.result);
      console.log('result PARSED JSON', result);
      const metadata = result.metadata;
      if(result.content) {
        //GET ENCRYPTED FILE CONTENT
        const encryptedFileContent = nacl.util.decodeBase64(result.content);
        //SET HOST PRIVATE KEY
        const privKey = hostKeyPair.secretKey;
        // console.log('privKey pair', privKey);
        //FIND RIGHT ENCRYPTED SYMMETRIC KEY
        const encSymKey = metadata.encryptedSymmetricKeys.find(x => x.user === myUsername);
        console.log('encSymKey', encSymKey);
        //DECODE NONCE
        const nonce = nacl.util.decodeBase64(encSymKey.nonce);
        console.log('nonce', nonce);
        //DECODE AND CAST TO UINT8ARRAY ENCRYPTED SYMMETRIC KEY
        const encryptedSymmetricKeyUint8 = new Uint8Array(nacl.util.decodeBase64(encSymKey.key));
        console.log('encryptedSymmetricKeyUint8', encryptedSymmetricKeyUint8);
        console.log('senderPublicKey', nacl.util.decodeBase64(metadata.senderPublicKey));
        //DECRYPT SYMMETRIC KEY
        const decryptedSymmetricKey = nacl.box.open(encryptedSymmetricKeyUint8, nonce, nacl.util.decodeBase64(metadata.senderPublicKey), privKey);
        console.log('decryptedSymmetricKey', decryptedSymmetricKey);
        // console.log('encryptedMessage', encryptedFileContent);
        //DECRYPT MESSAGE/FILE CONTENT WITH SYMMETRIC KEY
        const decryptedMessage = nacl.secretbox.open(encryptedFileContent, nacl.util.decodeBase64(metadata.messageNonce), decryptedSymmetricKey);

        // console.log('decryptedMessage', decryptedMessage);
        //CREATE FILE OBJECT FROM DECRYPTED MESSAGE
        const fileContentBuffer = decryptedMessage.buffer.slice(decryptedMessage.byteOffset, decryptedMessage.byteLength + decryptedMessage.byteOffset);
        console.log('fileContentBuffer', fileContentBuffer);
        const decryptedFile = new File([fileContentBuffer], fileName, {type: file.type});
        console.log('decryptedFile', decryptedFile);
        // console.log('-------END DECRYPTING MESSAGE-------');

        resolve(decryptedFile);
      }
    };
  });
  return newFile;
};

export const decryptUserFile = async (file, myUsername) => {
  const metadata = file.metadata;
  const content = file.content;

  if(metadata.nonce) {
    const nonce = nacl.util.decodeBase64(metadata.nonce);
    const encrypted = nacl.util.decodeBase64(content);

    // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, myUsername);
    // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
    // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));
    const hostKeyPair = await decryptPrivateKey();

    const decryptedMessage = nacl.secretbox.open(encrypted, nonce, hostKeyPair.publicKey);
    console.log('decryptedMessage', decryptedMessage)
    const parsedData = new TextDecoder().decode(decryptedMessage);

    const parsedJSON = JSON.parse(parsedData);
    console.log('parsedJSON', parsedJSON);
    return parsedJSON;
  }
  return {};
};

//Decryption
export const decryptPrivateKey = async () => {
  try {
    const keyPassword = sessionStorage.getItem('keyPassword');
    const encryptedKey = JSON.parse(localStorage.getItem(window.walletConnection.getAccountId() + '_encryptionKey'));

    console.log('encryptedKey', encryptedKey);
    console.log('keyPassword', keyPassword);
    
    const decryptHash = crypto.createHash('sha256');
    decryptHash.update(keyPassword);
    const decryptKey = decryptHash.digest();

    const buffer = Buffer.from(encryptedKey.iv, 'hex');
    const uint8ArrayIV = new Uint8Array(buffer.toJSON().data);
  
    const decipher = crypto.createDecipheriv('aes-256-cbc', decryptKey, uint8ArrayIV);
    let decryptedPrivateKey = decipher.update(encryptedKey.seed, 'hex', 'utf8');
    decryptedPrivateKey += decipher.final('utf8');
    let uint8Array;
  
    if(decryptedPrivateKey.includes(',')) { 
      // IF ITS FROM OLD VERSION OF PRIVATE KEY
      const numArray = decryptedPrivateKey.split(',').map(Number);
      uint8Array = new Uint8Array(numArray);
    }else {
      uint8Array = new Uint8Array(decryptedPrivateKey.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    }
    console.log('uint8Array KEY', uint8Array);
    const decoder = new TextDecoder();
    const keyString = decoder.decode(uint8Array);
    console.log('keyString', keyString);
    const decryptedKeyPair = nacl.box.keyPair.fromSecretKey(uint8Array);
  
    return decryptedKeyPair;
  }catch(e) {
    console.log('ERROR DECRYPTING PRIVATE KEY', e);
    return null;
  }
 
}