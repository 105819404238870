import styled from "styled-components";
import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/GlobalProvider';
import * as bs58 from 'bs58';
import cancelButton from '../assets/images/cancel-x.png';


import * as bip39 from 'bip39';
import crypto from 'crypto-browserify';
import nacl from 'tweetnacl';
import { saveAs } from 'file-saver';
import Dropzone from 'react-dropzone';


const ModalWrapper = styled.div`
    background: var(--BG_8, #283134);
    box-shadow: 0px 4px 76px 0px rgba(0, 0, 0, 0.65);
    border-radius: 32px;
    padding: 24px; 

    @media (max-width: 767px) {
      padding: 24px 8px;
    }
    
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .modal-title-wrap {
        .cancel-icon {
          width: 24px;
          height: 24px;
        }
      }

      .cancel-icon {
        width: 24px;
        height: 24px;
      }
    }

    .title {
      color: #FFF;
      font-family: Open Sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
    }

    .subtitle {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
    }

    .content {
      color: #FFF;
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
    }

    .message {
      color: #30BD5C;
      margin-bottom: 24px;
    }

    .modal-footer {
      justify-content: center;
    }

    .password,
    .seed-phrase {
      color: #FFF;
      font-weight: 500;
      background: var(--BG_8, #283134);
      border: 1px solid #FFF;
      padding: 10px;
      width: 100%;
      margin-top: 10px;
      border-radius: 5px;
      margin-bottom: 16px;
    }

    .seed-phrase {
      display: flex;
      padding: 48px 24px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px dashed #4C585F;
      cursor: pointer;

      .text-wrapper {
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #353C40;
      }
    }

    .info-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .additional-info {
        color: #FFF;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 155%;
      }

      .btn-primary {

        @media (max-width: 767px) {
          padding: 8px 16px;
          font-size: 15px;
        }
      }
    }
  `;


export default function EncryptionKeyModal({ handleClose }) {

  const { setEncryptionKey, oldPrivateKey, encryptionKey, openKeyModal, setOpenKeyModal, isKeyError, setOpenOnboardingModal } = useGlobalContext();
  const [openUploadSection, setOpenUploadSection] = useState(false);
  const [keyUploaded, setKeyUploaded] = useState(false);

  const [processing, setProcessing] = useState(false);
  const keyExists = localStorage.getItem(window.walletConnection.getAccountId() + '_encryptionKey');
  const keyPassword = sessionStorage.getItem('keyPassword');

  useEffect(() => {
    if(oldPrivateKey) {
      generateKeyPair();
    }
    
  }, []);

  useEffect(() => {
    if(openKeyModal && !oldPrivateKey) {
      setEncryptionKey(false);
    }
  }, [openKeyModal])

  const generateKeyPair = async () => {
    console.log('keyPassword', keyPassword);
    setProcessing(true);
    let privateKey;
    let keyUint8;
    if(!oldPrivateKey) {
      // console.log('no private key present')
      const mnemonic = bip39.generateMnemonic();
      // console.log('mnemonic', mnemonic);
      // Generate a seed from the mnemonic
      const seed = bip39.mnemonicToSeedSync(mnemonic);

      // console.log('seed', seed);
      // console.log('seed HR', seed.toString('hex'));
      
      // Derive a 32-byte key from the seed
      keyUint8 = crypto.pbkdf2Sync(seed, keyPassword, 100000, 32, 'sha512');
      privateKey = keyUint8.toString('hex');
    }else {
      // window.account.connection.signer.keyStore.setKey(window.account.connection.networkId, window.walletConnection.getAccountId(), oldPrivateKey);
      // console.log('KEY SET! ');
      // let hostKeyPairRaw = await window.account.connection.signer.keyStore.getKey(window.account.connection.networkId, window.walletConnection.getAccountId());
      // const hostPrivateKey = bs58.decode(hostKeyPairRaw.secretKey);
      // const hostKeyPair = nacl.box.keyPair.fromSecretKey(hostPrivateKey.slice(0, 32));
      // console.log('hostKeyPair old way', hostKeyPair);
      // console.log('private key present', oldPrivateKey);
      privateKey = oldPrivateKey;

      keyUint8 = bs58.decode(privateKey).slice(0, 32);
      privateKey = keyUint8.toString('hex');
    }
    
    // console.log('private key', privateKey);
    // console.log('keyUint8', keyUint8);
    // Generate KeyPair from the private key
    // const keyPair = nacl.box.keyPair.fromSecretKey(keyUint8)
    // console.log('keyPair', keyPair);

    // Create a SHA-256 hash of your password
    const hash = crypto.createHash('sha256');
    hash.update(keyPassword);
    const key = hash.digest();

    const iv = crypto.randomBytes(16);
    const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
    let encryptedPrivateKey = cipher.update(privateKey, 'utf8', 'hex');
    encryptedPrivateKey += cipher.final('hex');
    console.log('encryptedPrivateKey: ', encryptedPrivateKey);
    console.log('iv: ', iv.toString('hex'));
    // setEncryptedData({seed: encryptedPrivateKey, iv: iv.toString('hex')});
    localStorage.setItem(window.walletConnection.getAccountId() + '_encryptionKey', JSON.stringify({seed: encryptedPrivateKey, iv: iv.toString('hex')}));
    setEncryptionKey({seed: encryptedPrivateKey, iv: iv.toString('hex')});
    // console.log('ENCRYPTED: ', encryptedPrivateKey);
  };

  const uploadEncryptionKey = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async () => {
      const data = JSON.parse(reader.result);
      setEncryptionKey(data);
      localStorage.setItem(window.walletConnection.getAccountId() + '_encryptionKey', JSON.stringify(data));
      // setEncryptedData(data);
      setKeyUploaded(true);
    };
    reader.onerror = function () {
      console.log(reader.error);
    };
  }

  const finishProcess = async () => {
    // localStorage.setItem(window.walletConnection.getAccountId() + '_encryptionKey', JSON.stringify(encryptedData));
    if(!keyUploaded) {
      saveAs(new Blob([JSON.stringify(encryptionKey)]), window.walletConnection.getAccountId() + '_pathfinder.txt');
    }
    // setOldPrivateKey(false);
    setOpenKeyModal(false);
  }

  const openOnboardingSection = () => {
    if(!keyUploaded) {
      saveAs(new Blob([JSON.stringify(encryptionKey)]), window.walletConnection.getAccountId() + '_pathfinder.txt');
    }
    setEncryptionKey(false);
    setOpenKeyModal(false);
    setOpenOnboardingModal(true);
  }

  console.log('encryptionKey', encryptionKey);
  console.log('keyUploaded', keyUploaded);
  console.log('openUploadSection', openUploadSection);



  return (
    <Dialog open={true} show={handleClose} className="bg-transparent shadow-none" size="xs" >
      <ModalWrapper>
        <DialogHeader className="modal-header">
          <div className="modal-title-wrap">
            <p className="title">Welcome to Pathfinder!</p>
          </div>
          {
            keyExists &&
              <button className="close-btn" onClick={() => setOpenKeyModal(false)} >
                <img className="cancel-icon" src={cancelButton} alt="cancel button" />
              </button>
          }
          
        </DialogHeader>
        <DialogBody className="grid grid-cols-12 grid-rows-flow ">
          {
            (!encryptionKey && !openUploadSection ) &&
            <>
              <div className="col-span-12 row-span-1 mb-5">
                {
                  isKeyError ?
                    <p className="subtitle">{isKeyError}</p>
                  :
                  <>
                    <p className="subtitle">To ensure secure file transfers with other users, you will need to generate a secret key for encrypting and decrypting files.</p>
                    <br />
                    <p className="subtitle">This generated secret key will be stored only on your device, so it is crucial to keep it safe.</p>
                  </>
                }
                
              </div>
              <div className="col-span-12 row-span-2 info-wrap mt-5">
                <button className="btn-primary" disabled={processing} onClick={() => setOpenUploadSection(true)}>
                  Import key
                </button>
                <button className="btn-primary" disabled={processing} onClick={() => generateKeyPair()}>
                  Generate key
                </button>

              </div>
            </>
          }
          {
            openUploadSection && !keyUploaded && !encryptionKey &&
              <div className="col-span-12 row-span-2 mb-5">
                <p className="title mb-5">Upload encryption file</p>
                <p className="content">
                  Please upload your generated secret key, that you downloaded during the initial setup of your Pathfinder account. The file should be named in the following format: 
                  <br />
                  <b>your_account_id_pathfinder.txt</b>
                </p>

                <div className="info-wrap mt-5">
                  {/* <input type="file" className="seed-phrase" placeholder="Upload encryption key" onChange={(e) => uploadEncryptionKey(e) } /> */}
                  <Dropzone onDrop={(acceptedFiles) => uploadEncryptionKey(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                      <section className="seed-phrase" {...getRootProps()}>
                        <div className="text-wrapper">
                          <input {...getInputProps()} />
                          {
                            <>
                              <p className="input-text"><b>Upload file</b></p>
                              <p className="input-text">Drag'n'drop file here or click to upload.</p>
                            </>
                          }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                <div className="col-span-12 row-span-4 mt-5 info-wrap">
                  <button className="btn-primary" onClick={() => setOpenUploadSection(false)}>
                    Back
                  </button>
                </div>
              </div>
          }
          {
            encryptionKey && !keyUploaded &&
            <>
            <div className="col-span-12 row-span-2">
              <p className="title mb-5">Your encryption key has been generated!</p>
            </div>
            <div className="col-span-12 row-span-3 grid grid-flow-row">
              <p className="content">Once you click "Finish," the key will be securely stored on your device. We don't keep copies of your key, so be careful not to lose it!</p>
            </div>
            <div className="col-span-12 row-span-4 mt-5 info-wrap">
              <button className="btn-primary" onClick={() => openOnboardingSection()}>
                Intro to pathfinder
              </button>
              <button className="btn-primary" onClick={() => finishProcess()}>
                Finish
              </button>
            </div>
            </>
          }
          {
            encryptionKey && keyUploaded &&
            <>
              <div className="col-span-12 row-span-2 text-center">
                <p className="title mb-5">You have succesfully uploaded your encryption key!</p>
              </div>
              <div className="col-span-12 row-span-3 grid grid-flow-row">
                <p className="content">To proceed, select one of the following options below.</p>
              </div>
              <div className="col-span-12 row-span-4 mt-5 info-wrap">
                <button className="btn-primary" onClick={() => openOnboardingSection()}>
                  Intro to pathfinder
                </button>
                <button className="btn-primary" onClick={() => finishProcess()}>
                  Finish
                </button>
              </div>
            </>
          }
         
        </DialogBody>
      </ModalWrapper>
    </Dialog>
  )
}