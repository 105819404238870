import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { useGlobalContext } from '../context/GlobalProvider';

import syncImage from '../assets/images/sync.png';
import HelperIcon from '../assets/images/helper-icon.png';
import logo from '../assets/images/logo.png'
import downloadApp from '../assets/images/download-app.png';
import SyncFilesModal from '../modals/SyncFilesModal';
import WalletSelector from '../modals/WalletSelector';
import { saveAs } from 'file-saver';
import { logout } from '../assets/near/utils';

import logoutImage from '../assets/images/logout.png';

const NavbarWrapper = styled.div`
  padding-top: 21px;
  padding-bottom: 21px;

  &.landing-navbar {
    &.sticky {
      @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        padding-left: 16px;
        padding-right: 16px;
        background: rgba(5, 46, 40, 0.9);
        bacdrop-filter: blur(2px);
      }
    }
  }

  &.navigation-wrap {
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  
  .header-logo-wrap {
    display: flex;
    align-items: center;
  }

  .logo-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      margin-bottom: 16px;
    }

    .logo-img {
      @media (max-width: 768px) {
        max-width: 164px;
      }
    }
  }

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #menuToggle {
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;
    
    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: #000000;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
    
    cursor: pointer;
    
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    
    -webkit-touch-callout: none;
  }

  /*
  * Just a quick hamburger
  */
  #menuToggle span:not(.progress-badge) {
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    
    background: #FFFFFF;
    border-radius: 3px;
    
    z-index: 1;
    
    transform-origin: 3px 0px;
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-6px, -19px);
    background: #FFFFFF;
  }

  /*
  * But let's hide the middle one.
  */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
  * Ohyeah and the last one should go the other direction
  */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(1px, 12px)
  }

  .contact-link {
    color: #FFFFFF;
    font-family: Nexa;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-decoration: none;
    margin-right: 16px;
  }

  #landingMenuToggle {
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
  }

  #landingMenuToggle a {
    text-decoration: none;
    color: #232323;
    
    transition: color 0.3s ease;
  }

  #landingMenuToggle a:hover {
    color: #000000;
  }

  #landingMenuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
    
    cursor: pointer;
    
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    
    -webkit-touch-callout: none;
  }

  /*
  * Just a quick hamburger
  */
  #landingMenuToggle span:not(.progress-badge) {
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    
    background: #FFFFFF;
    border-radius: 3px;
    
    z-index: 1;
    
    transform-origin: 3px 0px;
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }

  #landingMenuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #landingMenuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #landingMenuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-6px, -19px);
    background: #FFFFFF;
  }

  /*
  * But let's hide the middle one.
  */
  #landingMenuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
  * Ohyeah and the last one should go the other direction
  */
  #landingMenuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(1px, 12px)
  }

  #landing-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: white;
    height: 100vh;
    z-index: 5;
    background: #042C25;


    .landing-navigation-close {
      position: absolute;
      top: 21px;
      right: 16px;
      color: #FFFFFF;
      font-size: 24px;
      cursor: pointer;
    }

    .landing-navigation-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      .logo-img-mobile {
        max-width: 200px;
        height: auto;
        position: absolute;
        top: 16px;
        left: 16px;
      }

      ul {
        li {
          padding: 16px 16px;
          color: #FFFFFF;
          font-family: Nexa;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: pointer;
          text-align: center;
          

          a {
            min-width: 180px;
            display: inline-block;
          }
        
        }
      }
    }
  }

  .profile-drawer {
    background: #353C40;
    border-radius: 8px;
    z-index: 999;
    top: 30px;
    left: 0;
    width: 100%;
    color: #FFFFFF;

    .list-item  {
      border-bottom: 1px solid #FFFFFF;
      padding: 16px 16px;

      &:last-child {
        border-bottom: none;
      }

      &.logout {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Red_3, #FA9C9C);
      }

      .logout-icon {
        margin-right: 6px;
        width: 20px;
        height: 20px;
      }

      .content {
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
      }
    }
  }

  .drawer-button {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .btn-secondary {

    &.download-bg-white {
      background: #FFFFFF;
      color: #000000;
    }
    img {
      width: 16px;
      height: 16px;
    }
  }

  .hide-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export default function Navbar() {

  const { isLoggedIn, myUsername, setOpenKeyModal, setOpenOnboardingModal } = useGlobalContext();

  const [openSyncModal, setOpenSyncModal] = useState(false);

  const [openWalletSelector, setOpenWalletSelector] = useState(false);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [direction, setDirection] = useState(false);

  const toggleDrawer = () => {
    setDirection(!isOpen);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', function() {
      var navbar = document.querySelector('.landing-navbar');
      var scrollPosition = window.scrollY;
      if(navbar) {
        if (scrollPosition > 40) {
          navbar.classList.add('sticky');
        } else {
          navbar.classList.remove('sticky');
        }
      }
    });
  }, []);

  const body = document.querySelector("body");

  const showMobileMenu = () => {
    const modal = document.querySelector("#menu");
    modal.classList.toggle("mobile-hide");
    
    if (!modal.classList.contains("mobile-hide")) {
        // Disable scroll
        body.style.overflow = "show-mobile";
    } else {
        // Enable scroll
        body.style.overflow = "auto";
    }

    const tabs = document.querySelector("#tabs-wrap");
    tabs.classList.toggle("mobile-hide");
   
  }

  const showLandingMobileMenu = () => {
    const modal = document.querySelector("#landing-menu");
    modal.classList.toggle("landing-mobile-hide");

    if (!modal.classList.contains("landing-mobile-hide")) {
        // Disable scroll
        body.style.overflow = "hidden";
    } else {
        document.querySelector('#landingMenuToggle input').checked = false;
        // Enable scroll
        body.style.overflow = "auto";
    }
   
  }

  const askForPermission = async () => {
    setOpenWalletSelector(true);
  }

  useEffect(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {

      window.addEventListener('beforeinstallprompt', (e) => {
        console.log('beforeinstallprompt fired');
        e.preventDefault();
        setDeferredPrompt(e);
      });
    }
  }, []);

  const installApp = async () => {

    if (!deferredPrompt) return;

    deferredPrompt.prompt();

    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('App installed');
      } else {
        console.log('App installation declined');
      }

      setDeferredPrompt(null);
    });
  };

  const SortIcon = () => {
    return direction === true ?
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-2 w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
      </svg>
    :
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-2 w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg> 
    ;
  };

  const downloadCurrentEncryptionKey = () => {
    const encryptedData = localStorage.getItem(window.walletConnection.getAccountId() + '_encryptionKey')
    saveAs(new Blob([JSON.stringify(encryptedData)]), window.walletConnection.getAccountId() + '_pathfinder.txt');
  }

  const formattedUsername = myUsername && myUsername.length >= 32 ? myUsername.slice(0,6) + '...' + myUsername.slice(-4) : myUsername;

  return (
    <NavbarWrapper className={isLoggedIn ? "navigation-wrap grid grid-cols-12 grid-flow-row" : "container mx-auto grid grid-cols-12 grid-flow-row landing-navbar"}>
      {
        openSyncModal &&
          <SyncFilesModal
            handleClose={() => setOpenSyncModal(false)}
          />
      }
      {
        openWalletSelector && <WalletSelector handleClose={() => setOpenWalletSelector(false)}/>
      }
        <div className={isLoggedIn ? "logo-wrap col-span-12 row-start-1 lg:col-span-2" : "col-span-7 lg:col-span-2 lg:col-start-1 header-logo-wrap"}>
          <img src={logo} alt="Logo" className="logo-img" />
          {
            isLoggedIn &&
              <nav role="navigation" className="hide-desktop">
                <div id="menuToggle">
                  <input type="checkbox" className="menu-checkbox" onClick={() => showMobileMenu()} />
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </nav>
          }
          
        </div>
        <div className={isLoggedIn ? "flex-wrapper col-span-12 lg:col-span-6 lg:col-start-7" : "flex-wrapper col-span-5 col-start-8 lg:col-span-5 lg:col-start-9 lg:ml-auto"}>
          {
            !isLoggedIn ?
            <>
              <nav role="navigation" className="hide-desktop">
                <div id="landingMenuToggle">
                  <input type="checkbox" className="menu-checkbox" onClick={() => showLandingMobileMenu()} />
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </nav>
              <div id="landing-menu" className="landing-mobile-hide">
                <div className="landing-navigation-wrap">
                  <img src={logo} alt="Logo" className="logo-img-mobile" />
                  <button className="landing-navigation-close" onClick={() => showLandingMobileMenu()}>X</button>
                  <ul>
                    <li>
                      <button href="#" className="btn-primary" onClick={() => askForPermission()}>Login/Register</button>
                    </li>
                    <li>
                      <a href="mailto:contact@pathfinderapp.xyz?subject=Explore%20New%20Horizons%3A%20Book%20Your%20Personalized%20Demo%20Today!&body=%0ADear%20Pathfinder%20Team%2C%0A%0AI%20am%20reaching%20out%20to%20express%20my%20interest%20in%20booking%20a%20demo.%20Our%20team%20is%20eager%20to%20explore%20the%20features%20and%20functionalities%20of%20your%20platform.%0A%0AThank%20you%20for%20considering%20our%20request.%20We%20look%20forward%20to%20the%20opportunity%20to%20explore%20Pathfinder%20further.%0A%0AKind%20regards%2C%0A%5BYour%20Name%5D%0A%5BYour%20Position/Company%5D%0A%5BContact%20Information%5D%0A" className="btn-primary">Book a demo</a>
                    </li>
                    <li>
                      <a href="mailto:contact@pathfinderapp.xyz?subject=Let%27s%20Connect%3A%20Your%20Path%20to%20Solutions%20Starts%20Here&body=Dear%20Pathfinder%20Team%2C%0A%0AI%20am%20reaching%20out%20to%20express%20my%20interest%20in%20booking%20a%20demo.%20Our%20team%20is%20eager%20to%20explore%20the%20features%20and%20functionalities%20of%20your%20platform.%0A%0AThank%20you%20for%20considering%20our%20request.%20We%20look%20forward%20to%20the%20opportunity%20to%20explore%20Pathfinder%20further.%0A%0AKind%20regards%2C%0A%5BYour%20Name%5D%0A%5BYour%20Position/Company%5D%0A%5BContact%20Information%5D" className="btn-primary">Contact us</a>
                    </li>
                  </ul>
                </div>
                
              </div>
              <a href="mailto:contact@pathfinderapp.xyz?subject=Let%27s%20Connect%3A%20Your%20Path%20to%20Solutions%20Starts%20Here&body=Dear%20Pathfinder%20Team%2C%0A%0AI%20am%20reaching%20out%20to%20express%20my%20interest%20in%20booking%20a%20demo.%20Our%20team%20is%20eager%20to%20explore%20the%20features%20and%20functionalities%20of%20your%20platform.%0A%0AThank%20you%20for%20considering%20our%20request.%20We%20look%20forward%20to%20the%20opportunity%20to%20explore%20Pathfinder%20further.%0A%0AKind%20regards%2C%0A%5BYour%20Name%5D%0A%5BYour%20Position/Company%5D%0A%5BContact%20Information%5D" className="hide-mobile contact-link">Contact us</a>
              <button className="hide-mobile btn-primary" onClick={() => askForPermission()}>
                Login/Register
              </button>
            </>
              
            :
              <div className="flex items-center">
                {
                  deferredPrompt &&
                    <button className="btn-secondary download-bg-white mr-4 ml-auto" onClick={() => installApp()}>
                      <img src={downloadApp} alt="sync" />
                      <span className="mobile-hide">Install app</span>
                    </button>
                }
                <button className="btn-secondary mr-4" onClick={() => setOpenOnboardingModal(true)}>
                  <img src={HelperIcon} alt="helper" />
                  <span className="mobile-hide">Helper</span>
                </button>
                <button className={deferredPrompt ? "btn-secondary mr-4" : " btn-secondary mr-4 ml-auto"} onClick={() => setOpenSyncModal(true)}>
                  <img src={syncImage} alt="sync" />
                  <span className="mobile-hide">Sync Files</span>
                </button>
                <div className="relative">
                  <button onClick={toggleDrawer} className="text-white mr-4 drawer-button">
                    {formattedUsername}
                    <SortIcon />
                  </button>
                  {isOpen && (
                    <div className="absolute profile-drawer">
                      <ul>
                        <li className="list-item" onClick={() => downloadCurrentEncryptionKey()}>
                          <p className="content">Export Key</p>
                        </li>
                        <li className="list-item" onClick={() => setOpenKeyModal(true)}>
                          <p className="content">Open Key Setup</p>
                        </li>
                        <li className="list-item logout" onClick={() => logout()}>
                          <img src={logoutImage} alt="logout" className="logout-icon" />
                          <p className="content">Logout</p>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
          }
        </div>
    </NavbarWrapper>
  );
}