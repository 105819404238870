import { BrowserRouter, Route } from "react-router-dom";
import { useGlobalContext } from "./context/GlobalProvider";
import PageWrapper from "./layout/PageWrapper";
import { UserFilesProvider } from "./context/UserFilesProvider";

const Main = () => {
  const { isLoggedIn } = useGlobalContext();

  return (
    <div className={isLoggedIn ? "log-in-background" : ""}>
      <UserFilesProvider>
        <BrowserRouter basename="/">
          <Route path="/:fileHashURL?" render={(props) => <PageWrapper {...props} /> } />
        </BrowserRouter>
      </UserFilesProvider>
    </div>
  );
}

export default Main;
