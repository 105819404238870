import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { Fragment, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import uploadIcon from "../assets/images/upload-icon.png";
import { db } from "../inc/database";
import { useUserFilesContext } from "../context/UserFilesProvider";
import nacl from "tweetnacl";
import { deleteFileFromIPFS, uploadFileToIPFS } from "../inc/storage";
import { encryptFile, encryptUserFile } from "../inc/encryption";
import { addFileToContract, checkUserIPFS } from "../assets/near/utils";
import { useGlobalContext } from "../context/GlobalProvider";
import { deleteFileFromMachina, uploadFileToMachina } from "../inc/storageOnMachina";

const { default: styled } = require("styled-components")

const UploadFileWrap = styled.div`

  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  .accordion-header {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    padding: 0;
    border-bottom: 0;

    .title {
      color: rgba(255, 255, 255, 0.90);
      font-family: Open Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
    }

    .accordion-icon {
      width: 24px;
      height: 24px;
      margin-left: 16px;
    }
  }

    .dropzone {
      display: flex;
      padding: 48px 24px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 16px;
      border: 1px dashed #4C585F;
      cursor: pointer;
    }

    .text-wrapper {
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: #353C40;
      margin-bottom: 24px;
      min-width: 355px;
      min-height: 145px;
    }

    .upload-icon {
      margin-bottom: 8px;
      width: 30px;
      height: 30px;
    }

    .input-text {
      text-align: center;
      color: #FFF;
      font-family: Open Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
      margin-bottom: 8px;

      b {
        font-weight: 700;
      }
    }

    .btn-primary {
      &:disabled {
        background: var(--Mint_8, #057272);
      }
    }
  `;
  
export default function UploadFile() {

  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const { setUploadedFiles } = useUserFilesContext();
  const { myUsername, socket} = useGlobalContext();

  // const urlParams = new URLSearchParams(window.location.search);
  // let queryParams = urlParams.get("signMeta");
  // const fileIds = queryParams.split("|");
  // let isErrorResponse = urlParams.get("errorCode");
  // useEffect(() => {
    
  //   if(isErrorResponse && fileIds[2] === 'isUpload') {
  //     deleteFileUserRejected();
  //   }
     
  // }, []);

  // const deleteFileUserRejected = async () => {
  //   const file = await db.files.where('fileId').equals(fileIds[0]).first();

  //   if(file.cid_public) {
  //     console.log('deleteFileFromIPFS PUBLIC: ', file.cid_public);
  //     await deleteFileFromIPFS(file.cid_public);
  //   }
  //   if(file.cid_private) {
  //     console.log('deleteFileFromIPFS PRIVATE: ', file.cid_private);
  //     await deleteFileFromIPFS(file.cid_private);
  //   }
  // };

  const scrollToUploadSection = () => {
    const element = document.getElementById("upload-section");
    console.log('element', element);
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  };

  useEffect(() => {
    if(selectedFile) {
      uploadFile();
    }
  }, [selectedFile]);
  

  const uploadFile = async () => {
    console.log('selectedFile', selectedFile);
    if(selectedFile.length > 0) {

      console.log('TRIGGERED UPLOAD');
      setDisableSubmitButton(true);
      scrollToUploadSection();
      for(const [index, file] of selectedFile.entries()) {
        const fileId = (Date.now() + index).toString();
        selectedFile[index].fileId = fileId;
        setUploadedFiles((prev) => {
          return [{fileId: fileId, name: file.name, size: file.size, type: file.type, percentage: 0}, ...prev];
        })
      }
      for(const uploadFile of selectedFile) {

        setFileName(uploadFile.name);
        //READ FILE CONTENT
        const reader = new FileReader();
        reader.readAsArrayBuffer(uploadFile);
        const fileContent = await new Promise((resolve) => {
          reader.onload = () => resolve(reader.result);
        });

        console.log('fileContent', fileContent);
        // Generate a random symmetric key
        const symmetricKey = nacl.randomBytes(nacl.secretbox.keyLength);
        console.log('symmetricKey', symmetricKey);
        
        // Generate a random nonce for use with AES encryption
        const messageNonce = nacl.randomBytes(nacl.secretbox.nonceLength);

        // Convert the file content to Uint8Array format
        const fileContentUi8 = new Uint8Array(fileContent);
        // Encrypt the file content using the symmetric key and nonce
        let encryptedMessage = nacl.secretbox(fileContentUi8, messageNonce, symmetricKey);

        const fileBlob = new Blob([encryptedMessage], {type: uploadFile.type});
        const file = new File([fileBlob], uploadFile.name, {type: uploadFile.type});

        setUploadedFiles((prev) => {
          const newFiles = [...prev];
          const index = newFiles.findIndex((file) => file.fileId === uploadFile.fileId);
          const newFile = {...newFiles[index], percentage: 25};
          newFiles[index] = newFile;
          return newFiles;
        });

        // const cid_public = await uploadFileToIPFS(file, myUsername, uploadFile.fileId);
        const cid_public = await uploadFileToMachina(file, myUsername, uploadFile.fileId);
        console.log('UPLOADED FILE TO IPFS', cid_public);
        const data = {
          file: uploadFile,
          cid_public: cid_public,
          cid_private: null,
          cid_contract: null,
          nonce: nacl.util.encodeBase64(messageNonce),
          key: nacl.util.encodeBase64(symmetricKey),
          owner: myUsername,
          users: [],
          fileId: uploadFile.fileId,
          name: uploadFile.name,
          type: uploadFile.type,
          size: uploadFile.size,
        };
        console.log('data', data);

        setUploadedFiles((prev) => {
          const newFiles = [...prev];
          const index = newFiles.findIndex((file) => file.fileId === uploadFile.fileId);
          const newFile = {...newFiles[index], percentage: 75};
          newFiles[index] = newFile;
          return newFiles;
        });
        
        console.log('File: ', uploadFile);
        const id = await db.files.add(data);
        // console.log('id', id);

        //TODO - optimize so that we don't have to update the file, instead everything should be done at once
        const encryptedFile = await encryptFile(uploadFile, [], myUsername, true);
        // return;
        // const cidPrivate = await uploadFileToIPFS(encryptedFile, myUsername, id);
        const cidPrivateId = (Date.now() + 1).toString();
        const cidPrivate = await uploadFileToMachina(encryptedFile, myUsername, cidPrivateId);
        console.log('cidPrivate', cidPrivate);

        await db.files.update(id, {
          file: encryptedFile,
          size: encryptedFile.size,
          cid_private: cidPrivate,
          cid_contract: cidPrivate + '|null',
        });

        console.log("File: ", selectedFile);
        console.log("Selected users: ", {fileId: uploadFile.fileId, name: uploadFile.name, size: uploadFile.size, type: uploadFile.type});
        const document = [{
          fileId: uploadFile.fileId,
          name: encryptedFile.name,
          size: encryptedFile.size,
          type: encryptedFile.type,
          owner: myUsername,
          date: new Date().toString(),
          users: [],
          cid_private: cidPrivate,
          cid_public: cid_public,
          cid_contract: cidPrivate + '|null',
        }];
        socket.emit("upload_file", {
          document,
          username: myUsername
        });
        //ADD LOCAL DB FILE ID TO DOCUMENT
        document[0].id = id;

        //Check if user has an IPFS hash stored on the blockchain
        const currentUserIPFS = await checkUserIPFS(window.accountId);
        console.log('currentUserIPFS', currentUserIPFS);
        const regExp = /[a-zA-Z]/g;

        if(currentUserIPFS && regExp.test(currentUserIPFS)) {
          await deleteFileFromIPFS(currentUserIPFS);
        }else if(currentUserIPFS) {
          await deleteFileFromMachina(window.accountId, currentUserIPFS);
        }

        setUploadedFiles((prev) => {
          const newFiles = [...prev];
          const index = newFiles.findIndex((file) => file.fileId === uploadFile.fileId);
          const newFile = {...newFiles[index], percentage: 80};
          newFiles[index] = newFile;
          return newFiles;
        });

        //Encrypt user file but send current document ID so that it doesn't include it in new file - it will be deleted after confirmation of sharing
        const userFile = await encryptUserFile(myUsername);
        console.log('USER FILE', userFile);
        //Upload user file to IPFS
        // const wallet_ipfs = await uploadFileToIPFS(userFile, myUsername, uploadFile.fileId);
        const wallet_ipfs = await uploadFileToMachina(userFile, myUsername, (Date.now() + 1).toString());
        console.log('wallet_ipfs', wallet_ipfs);
        
        // setProgressBarData({message: 'Redirecting you to the confirmation window.', percentage: 100});
        //Update blockchain with new file data
        console.log('new contract_cid', data.cid_contract);
        console.log('cid_private', cidPrivate);

        // await addFileToContract([], '', cidPrivate.ipnft + '|null', wallet_ipfs.ipnft, id, uploadFile.fileId);
        await addFileToContract([], '', cidPrivate + '|null', wallet_ipfs, id, uploadFile.fileId);

        console.log('document', document)
        // setUploadedFiles([...document, ...uploads]);

        setUploadedFiles((prev) => {
          const newFiles = [...prev];
          const index = newFiles.findIndex((file) => file.fileId === uploadFile.fileId);
          const newFile = {...document[0], percentage: 100};
          newFiles[index] = newFile;
          return newFiles;
        });
      }

      setFileName(null);
      setSelectedFile(null);
      setDisableSubmitButton(true);
    }else {
      console.log("No file selected");
    }
  };
  
  const [open, setOpen] = useState(1);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  
  const onFileDrop = (acceptedFiles) => {
    console.log(acceptedFiles[0]);
    let files = [];
    for(let file of acceptedFiles) {
      const newFile = new File([file], file.name.toString().replace(/[^0-9A-Za-z.\-_() ]/gi, ''), {type: file.type});
      files.push(newFile);
    }
    
    setFileName(acceptedFiles[0].name);
    setSelectedFile(files);
    setDisableSubmitButton(false);
  }

  return (
    <UploadFileWrap>
      <Accordion open={open === 1}>
        <AccordionHeader className="accordion-header" onClick={() => handleOpen(1)}>
          <h3 className="title">Upload File</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="#fff"
            className={`${1 === open ? "rotate-180" : ""} accordion-icon transition-transform`}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </AccordionHeader>
        <AccordionBody>
          <Dropzone onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
              <section className="dropzone" {...getRootProps()}>
                <div className="text-wrapper">
                  <input {...getInputProps()} />
                  <img className="upload-icon" src={uploadIcon} alt="Upload icon" />
                  {
                    fileName ?
                    <p className="input-text"><b>{fileName}</b> <br/>is currently uploading...</p>
                    :
                    <Fragment>
                      <p className="input-text"><b>Upload file</b></p>
                      <p className="input-text">Drag'n'drop file here or click to upload.</p>
                    </Fragment>
                  }
                </div>
                <p className="btn-primary">Upload</p>
              </section>
            )}
          </Dropzone>
        </AccordionBody>
      </Accordion>
      
    </UploadFileWrap>
  );
}
