import styled from "styled-components";
import { Fragment, useEffect, useState } from "react";
import { formatBytes } from "../../../inc/functions";
import { Input, Typography } from "@material-tailwind/react";
import MetricInfoModal from "../../../modals/MetricInfoModal";

const UploadHistoryWrap = styled.div`
  .row-wrap {
    padding: 0 15px;
  }
  .border-bottom {
    height: 1px;
    background: #D9D9D9;
  }

  .background-gray {
    background: #283134;
  }


  .table-header-wrap {
    color: #C0CDCF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .td-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .table-header {
    color: #C0CDCF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;

    svg {
      display: inline-block;
    }
  }

  .content-wrap {
    color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    min-height: 95px;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.btn-wrap {
      margin-left: auto;
    }

    .file-info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: rgba(255, 255, 255, 0.90);
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
      
      .owner {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .search-wrap {
    margin-left: auto;
  }


  .action-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .no-transactions {
      color: rgba(255, 255, 255, 0.90);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
  }

  .close-btn {
    width: 24px;
    height: 24px;
  }
`;

export default function UploadHistory({ uploadFileHistory }) {
  
  const TABLE_HEAD = ["Name", "Size", "Date", "Search"];

  const [openMetricInfo, setOpenMetricInfo] = useState(false);
  const [metricData, setMetricData] = useState({});

  const [tableData, setTableData] = useState(uploadFileHistory);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');

  //Sort files by date DESC
  useEffect(() => {
    // uploadFileHistory.sort((a, b) => b.date - a.date);
    setTableData(uploadFileHistory);
  }, [uploadFileHistory]);

  const showFileMetric = (file) => {
    setMetricData(file);
    setOpenMetricInfo(true);
  };

  const SortIcon = ({ direction }) => {
    return direction === 'asc' ?
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
      </svg>
    :
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg> 
    ;
  };

  // Function to handle sorting
  const requestSort = (key) => {
    if(key === 'Search') {
      return;
    }
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    console.log('sortConfig', sortConfig)
  };

  // Function to search the table
  const searchTable = () => {
    return tableData.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const sortedData = searchTable().sort((a, b) => {
    if (sortConfig.key) {
      let currentKey = sortConfig.key.toLowerCase();
      console.log('currentKey', sortConfig.key)
        
      const aValue = a[currentKey].toString();
      const bValue = b[currentKey].toString();

      console.log('aValue', aValue)
      console.log('bValue', bValue)
      
      if (aValue && bValue) {
        const order = sortConfig.direction === 'asc' ? 1 : -1;
        return aValue.localeCompare(bValue) * order;
      }
    }
    return 0;
  });

console.log('uploadFileHistory', uploadFileHistory);
  return (
    <UploadHistoryWrap>
      {
        openMetricInfo && <MetricInfoModal handleClose={() => setOpenMetricInfo(false)} metricData={metricData} isShared={false}/>
      }
       <div className="table-wrap">
        <div className="table-header-wrap grid grid-cols-8 grid-row-flow mobile-hide-grid">
          {
            TABLE_HEAD.map((head, index) => {
              let className = "";
              if(head === 'Name') {
                className="col-span-4 lg:col-span-3 td-wrap";
              }else if(head === 'Date' || head === 'Size') {
                className="col-span-1 td-wrap";
              }else if(head === 'Search') {
                className="col-span-2 col-start-9 lg:col-span-3 lg:col-start-7 td-wrap";
              }
              let currentDirection = 'asc';
              if(sortConfig.key === head) {
                currentDirection = sortConfig.direction;
              }
              return (
                <div key={head} className={className} onClick={() => requestSort(head)} >
                  <div
                    variant="small"
                    color="blue-gray"
                    className={index === TABLE_HEAD.length - 1 ? "table-header search-wrap" : "table-header"}
                  >
                    {index === TABLE_HEAD.length - 1 ? (
                      <Input
                        label="Search"
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        color="white"
                      />
                    ):
                      <>
                        {head}{" "}
                        <SortIcon direction={currentDirection} />
                      </>
                    }
                  </div>
                  </div>);
              })
            }
        </div>
        {
          sortedData.length > 0 ?
            <Fragment>
            {
            sortedData.map((file, index) => {
              const fileDate = new Date(parseInt(file.date)).toLocaleString().split(',')[0];
              const name = file.name.length > 50 ? file.name.substring(0, 50) + '...' : file.name;
              const username = file.sharedTo.length >= 32 ? file.sharedTo.slice(0,6) + '...' + file.sharedTo.slice(-4) : file.sharedTo;
              const trClass = index % 2 === 0 ? 'background-gray grid grid-cols-8 grid-row-flow row-wrap' : 'grid grid-cols-8 grid-row-flow row-wrap';

              return (
                <div key={index} className={trClass}>
                  <div className="col-span-4 lg:col-span-3 content-wrap">
                    <div className="file-info-wrap">
                      <p className="name">{name}</p>
                      <p className="owner">{username}</p>
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1 content-wrap">
                    <p className="size">{formatBytes(file.size)}</p> 
                  </div>
                  <div className="col-span-2 lg:col-span-1 content-wrap">
                    <p className="file-date">{fileDate}</p> 
                  </div>
                  <div className="col-span-2 lg:col-span-3 content-wrap btn-wrap">
                    <button className="btn-primary" onClick={() => showFileMetric(file)}>Details</button>
                  </div>
                </div>
                );
              })
            }
            </Fragment>
            :
            <p className="no-transactions">Your downloaded file archive is empty, time to make history!</p>
          }
      </div>
    </UploadHistoryWrap>
  )
}