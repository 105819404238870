import { Input, Typography } from "@material-tailwind/react";
import { useUserFilesContext } from "../../context/UserFilesProvider";

import downloadIcon from '../../assets/images/download-icon.png';
import { formatBytes } from "../../inc/functions";
import { peerConfig } from "../../inc/constants";
import { useGlobalContext } from "../../context/GlobalProvider";
import { downloadDirectlyFromIpfs } from "../../inc/storage";
import { useEffect, useState } from "react";
import redCancelBtn from '../../assets/images/cancel-x-red.png';
import downloadingBtn from '../../assets/images/downloading-btn.png';
import FileActionButtons from "../FileActionButtons";

const { default: styled } = require("styled-components")

const AvailableFilesWrap = styled.div`

    .background-gray {
      background: #283134;
    }

    .row-wrap {
      padding: 0 15px;

      @media (max-width: 768px) {
        padding-bottom: 15px;
      }
    }

    .title {
      color: rgba(255, 255, 255, 0.90);
      font-family: Open Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        padding-left: 12px;
      }
    }

    .table-header-wrap {
      color: #C0CDCF;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
    }
  
    .td-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .table-header {
      color: #C0CDCF;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 16px;
  
      svg {
        display: inline-block;
      }
    }
 
    .content-wrap {
      color: rgba(255, 255, 255, 0.90);
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
      min-height: 95px;
      padding: 5px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .file-info-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: rgba(255, 255, 255, 0.90);
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 155%;
        
        .owner {
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .action-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .file-wrap {

      .action-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .action-info {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 32px;
          color: rgba(255, 255, 255, 0.90);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 155%;

          img {
            animation: rotation 2s infinite linear;
            margin-right: 8px;
          }

          @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(359deg);
            }
          }
        }

        .cancel-btn {
          float: right;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
  
          img {
            width: 16px;
            height: 16px;
            
          }
         
        }
      }

      .progress-bar-wrap {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 10px;
    
      
        .progress-bar {
          width: 100%;
          height: 10px;
          border-radius: 24px;
          background: var(--Bg_6, #404B51);
    
        }
      
        .percentage {
          color: rgba(255, 255, 255, 0.90);
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 155%;
          margin-left: 5px !important;
        }
      }
    }

    .search-wrap {
      margin-left: auto;
      
      > div {
        min-width: 0;
      }
    }

    .no-files {
      color: rgba(255, 255, 255, 0.90);
      font-family: Open Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 155%;
  
      @media (max-width: 768px) {
        padding-left: 12px;
      }
    }
    

    .btn-secondary {
      margin-left: auto;
    
      .download-icon {
        width: 16px;
        height: 16px;
      }
    }
  `;

export default function AvailableFiles({setCurrentActivePage}) {

  const { availableFiles, downloads, setDownloads, peerInstanceReceiver, peerInstanceHost } = useUserFilesContext();
  const { myUsername, socket } = useGlobalContext();

  const [tableData, setTableData] = useState(availableFiles);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');

  const TABLE_HEAD = ["Name", "Date", "Size", "Search"];
  const officeRestrictions = ['docx', 'pptx'];

  useEffect(() => {
    
    const mergedArray = availableFiles.map((file) => {
      const obj = downloads.find((item) => item.fileId === file.cid_private || item.fileId === file.cid_pdf);
      if(obj) {
        const res = {...file, ...obj, date: file.fileId};
        return res;
      }
      return {...file, date: file.fileId};
    });
    setTableData(mergedArray);
  }, [downloads, availableFiles])

   //START DOWNLOAD PROCESS FOR DOCUMENT
   const downloadDocumentP2P = async (fileId, owner, name, isPdf = null) => {
    console.log('Download document P2P: ', fileId, owner, name);
    let progress = {};
    progress['user'] = owner;
    progress['percentage'] = 0;
    progress['downloading'] = true;
    progress['verified'] = false;
    progress['fileName'] = name;
    progress['fileId'] = fileId;
    progress['statusMessage'] = 'Initializing P2P connection...';

    setDownloads((prevState) => {
      let newState = [...prevState];
      if(newState.length > 0) {
        const index = newState.findIndex(object => object.fileId === progress.fileId);
        if (index === -1) {
          newState = [...prevState, progress];
        } else {
          newState[index] = progress;
        }
      }else {
        newState = [...prevState, progress];
      }
      return [...newState];
    });

    console.log('current fileID', fileId)
    //TODO disable multiple instances of same file - save file in DB
    const peer = new window.SimplePeer({
      initiator: false,
      trickle: false,
      config: peerConfig
    });
    peerInstanceReceiver.current[fileId] = peer;
    socket.emit('init_download', {fileId: fileId, username: myUsername, isPdf: isPdf, anonymousTransfer: false});
  };

  const downloadDocumentIPFS = async (cid, owner, fileName ) => {
    console.log('Download document IPFS: ', cid, owner, fileName);

    const sharedFile = availableFiles.find((file) => file.cid_private === cid || file.cid_pdf === cid);
    console.log('sharedFile', sharedFile)
    const newProgress = {};
    newProgress['fileName'] = fileName;
    newProgress['fileId'] = cid;
    newProgress['percentage'] = 0;
    newProgress['downloading'] = true;
    newProgress['verified'] = false;
    newProgress['anonymous'] = false;
    newProgress['user'] = owner;
    newProgress['rawPermissions'] = sharedFile['rawPermissions'];
    newProgress['readPermissions'] = sharedFile['readPermissions'];
    newProgress['watermarkPermissions'] = sharedFile['watermarkPermissions'];
    newProgress['statusMessage'] = 'Initializing IPFS connection...';
    
    // console.log('currentSharedFiles', currentSharedFiles)
    // console.log('fileId', cid)
    console.log('newProgress', newProgress)

    setDownloads((prevState) => {
      let newState = [...prevState];
      if(newState.length > 0) {
        const index = newState.findIndex(object => object.fileId === newProgress.fileId);
        if (index === -1) {
          newState = [...prevState, newProgress];
        } else {
          newState[index] = newProgress;
        }
      }
      console.log('newState download private document', newState);
      return [...newState];
    });
    downloadDirectlyFromIpfs(cid, setDownloads, availableFiles, myUsername, owner);
    // setCurrentActivePage('current-transfers');
  };

  const SortIcon = ({ direction }) => {
    return direction === 'asc' ?
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
      </svg>
    :
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg> 
    ;
  };

  // Function to handle sorting
  const requestSort = (key) => {
    if(key === 'Search') {
      return;
    }
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    console.log('sortConfig', sortConfig)
  };

  // Function to search the table
  const searchTable = () => {
    return tableData.filter((item) =>
      Object.values(item).some((value) => {
        if(value) {
          return value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        }
        return false;
      })
    );
  };

  const sortedData = searchTable().sort((a, b) => {
    if (sortConfig.key) {
      let currentKey = sortConfig.key.toLowerCase();
      console.log('currentKey', sortConfig.key)
        
      const aValue = a[currentKey].toString();
      const bValue = b[currentKey].toString();

      console.log('aValue', aValue)
      console.log('bValue', bValue)
      
      if (aValue && bValue) {
        const order = sortConfig.direction === 'asc' ? 1 : -1;
        return aValue.localeCompare(bValue) * order;
      }
    }
    return 0;
  });

  const cancelTransfer = (isDownloading, username, fileId) => {
    console.log('cancelTransfer', isDownloading, username, fileId);
    socket.emit('cancel_download', {username: username, isDownloading: isDownloading, myUsername: myUsername, fileId: fileId});
    if(isDownloading) {
      //CHECK IF TRANSFER ALREADY STARTED
      if(peerInstanceReceiver.current[fileId]) {
        console.log('destroying peer');
        peerInstanceReceiver.current[fileId].destroy();
      }
    }else {
      //CHECK IF TRANSFER ALREADY STARTED
      if(peerInstanceHost.current[username + fileId]) {
        peerInstanceHost.current[username + fileId].destroy();
      }
    }
    //TODO Change currentPercentage to filter by FILE ID
    setDownloads((prevState) => {
      return prevState.filter(el => el.fileId !== fileId);
    });
  };

  
  return (
    <AvailableFilesWrap className="grid grid-flow-row overflow-scroll">
      <h2 className="title row-start-1">Downloads</h2>
        <div className="table-wrap">
          <div className="table-header grid grid-cols-8 grid-row-flow mobile-hide-grid">
            {
              TABLE_HEAD.map((head, index) => {
                let className = "";
                if(head === 'Name') {
                  className="col-span-4 lg:col-span-3 td-wrap";
                }else if(head === 'Date' || head === 'Size') {
                  className="col-span-1 td-wrap";
                }else if(head === 'Search') {
                  className="col-span-2 col-start-9 lg:col-span-3 lg:col-start-7 td-wrap";
                }
                let currentDirection = 'asc';
                if(sortConfig.key === head) {
                  currentDirection = sortConfig.direction;
                }
                return (
                  <div key={head} className={className} onClick={() => requestSort(head)} >
                    <div
                      variant="small"
                      color="blue-gray"
                      className={index === TABLE_HEAD.length - 1 ? "table-header search-wrap" : "table-header"}
                    >
                      {index === TABLE_HEAD.length - 1 ? (
                          <Input
                            label="Search"
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            color="white"
                          />
                        ):
                          <>
                            {head}{" "}
                            <SortIcon direction={currentDirection} />
                          </>
                        }
                    </div>
                    </div>);
              })
            }
          </div>
            {
              sortedData.length === 0 ? (
                <p className="no-files">You currently dont have any shared files</p>
              )
              :
                sortedData.map((file, index) => {
                  // console.log('percentage FILE', file, file.hasOwnProperty('file'))

                  const fileDate = new Date(parseInt(file.date)).toLocaleString().split(',')[0];
                  const ownerFormatted = file.owner.length >= 32 ? file.owner.slice(0,6) + '...' + file.owner.slice(-4) : file.owner;
                  const fileExtension = file.name.split('.').pop();
                  const trClass = index % 2 === 0 ? 'background-gray grid grid-cols-8 grid-row-flow row-wrap' : 'grid grid-cols-8 grid-row-flow row-wrap';

                  const fillerStyles = {
                    height: '100%',
                    width: `${file.percentage && file.percentage > 0 ? file.percentage : 0}%`,
                    background: '#06C5C5',
                    borderRadius: '24px',
                  }

                  return (
                    <div key={file.name} className={trClass}>
                      <div className="col-span-4 lg:col-span-3 content-wrap">
                        <div className="file-info-wrap">
                          <p className="name">{file.name}</p>
                          <p className="owner">{ownerFormatted}</p>
                        </div>
                      </div>
                      <div className="col-span-2 lg:col-span-1 content-wrap">
                          <p className="date">{fileDate}</p>
                      </div>
                      <div className="col-span-2 lg:col-span-1 content-wrap">
                          <p className="size">{formatBytes(file.size)}</p>
                      </div>
                      <div className="col-span-8 lg:col-span-3 action-btn-wrap">
                        {
                          file.hasOwnProperty('percentage') ?
                            !file.hasOwnProperty('file') ? (
                              <div className="file-wrap">
                                <div className="action-wrap">
                                  <p className="action-info">
                                    <img src={downloadingBtn} alt="cancel-btn" />
                                    {file.statusMessage}
                                  </p>
                                  <button className="cancel-btn" onClick={() => cancelTransfer(file.downloading, file.user, file.fileId)}>
                                    <img src={redCancelBtn} alt="cancel-btn" />
                                    Cancel
                                  </button>
                                </div>
                                <div className="progress-bar-wrap">
                                  <div className="progress-bar">
                                    <p style={fillerStyles}></p>
                                  </div>
                                  <p className="percentage">
                                    {`${file.percentage}%`}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <FileActionButtons currentFile={file} />
                            )
                          :
                          <button className="btn-secondary" onClick={() => {
                              if(file.ownerOnline) {
                                if(file.readPermissions) {
                                  if(file.isOriginalPdf) {
                                    downloadDocumentP2P(file.cid_private, file.owner, file.name);
                                  }else {
                                    downloadDocumentP2P(file.cid_pdf, file.owner, file.name, true);
                                  }
                                }else {
                                  //check if file is docx or pptx
                                  if(officeRestrictions.includes(fileExtension) && !file.readPermissions && !file.watermarkPermissions && !file.rawPermissions) {
                                    downloadDocumentP2P(file.cid_pdf, file.owner, file.name, true);
                                  }else {
                                    downloadDocumentP2P(file.cid_private, file.owner, file.name);
                                  }
                                }
                              }else {
                                if(file.readPermissions) {
                                  if(file.isOriginalPdf) {
                                    downloadDocumentIPFS(file.cid_private, file.owner, file.name);
                                  }else {
                                    downloadDocumentIPFS(file.cid_pdf, file.owner, file.name, true);
                                  }
                                }else {
                                  //check if file is docx or pptx
                                  if(officeRestrictions.includes(fileExtension) && !file.readPermissions && !file.watermarkPermissions && !file.rawPermissions) {
                                    downloadDocumentIPFS(file.cid_pdf, file.owner, file.name);
                                  }else {
                                    downloadDocumentIPFS(file.cid_private, file.owner, file.name);
                                  }
                                }
                              }
                              }}>
                            <img className="download-icon" src={downloadIcon} alt="download-icon" />
                            <span>Download</span>
                          </button>
                        }
                      </div>
                    </div>
                  );
                },
              )}
        
        </div>
    </AvailableFilesWrap>
  );
}
